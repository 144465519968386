import React from 'react';
import cx from 'classnames';
import {
	get,
	map,
	find,
	values,
} from 'lodash';
import {
	useLocation,
	useHistory,
} from 'react-router-dom';
import useStdQuery from "components/data/hooks/useStdQuery.js";
import TokenStatus from 'components/account/card/TokenStatus.js';
import { GET_EMAIL } from "components/data/session-user/SessionUser";

import AccountLayout from 'layouts/AccountLayout.js';
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';
import CmsContentRenderer, { findContentOrFallback } from "components/data/CmsContentRenderer.js";
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import { ccMonthYearDisplay } from 'utils/FormatHelpers.js';
import { useModalContext } from 'context/ModalProvider.js';

import Panel from 'components/Panel.js';
import Button, { Primary } from 'components/Button.js';
import { getCardIcon } from 'components/Icon.js';
import Toast from 'components/Toast.js';
import EditPaymentMethod from 'components/modals/EditPaymentMethod.js';
import ManageMenu from 'components/account/ManageMenu.js';
import InfoPanel from 'components/InfoPanel.js';

import { OPTION_EDIT, OPTION_REMOVE, TYPE_DANGER } from 'components/Dropdown.js';
import CmsContentList from 'components/data/CmsContentList';
import { IsNotAlternate } from "components/data/alternates/SessionAlternate.js";
import { useFundingSources } from 'components/data/session-user/FundingSources.query.js';
import useFormHelper from "utils/form-helper/useFormHelper.js";
import DeletePaymentMethodConfirmation from 'pages/account/settings/payment-methods/DeletePaymentMethodConfirmation.js';

import * as panel from 'components/Panel.module.css';
import * as style from './PaymentMethods.module.css';
import * as infoPanel from 'components/InfoPanel.module.css';
import { getCharlieCardBreadcrumbs } from '../CardSelection';
import { BreadcrumbItem } from 'components/breadcrumb/Breadcrumb';
import { useBreadcrumbCallback } from 'context/BreadcrumbProvider';
import { ComponentLoading } from 'components/icons/LoadingIcon.js';
import PublicAppVars from 'utils/PublicAppVars.js';
import ComingSoon from 'components/banners/ComingSoon';

/**
 * Data-QA Tags:
 * PaymentMethodsText, PaymentMethodsAction
 * PaymentMethodsNoCardsTitle, PaymentMethodsNoCardsText, PaymentMethodsNoCardsAction
 */

export const findPrimaryBillingAddressId = (fundingSources) => {
	const data = find(fundingSources, source => source?.setAsPrimary === true);
	return data?.billingAddressId;
};


const cms = {
	header: "miscText.payments-header",
	addedToastTitle: '',
	addedToastText: 'miscHtml.payments-add-confirmation',

	description: 'miscText.payments-description',
	ending: 'miscText.payments-info-last4',
	expires: 'miscText.payments-info-expiry',
	default: 'miscText.payments-info-default',

	manageLabel: 'miscText["payments-info-manage.label"]',
	manageAria: 'miscText["payments-info-manage.aria"]',
	manageEdit: 'miscText.payments-info-manage-edit',
	manageEditAria: 'miscText.payments-info-manage-edit-aria',
	manageDelete: 'miscText.payments-info-manage-delete',
	manageDeleteAria: 'miscText.payments-info-manage-delete-aria',

	noMethodsHeading: 'miscText.payments-empty-subheader',
	noMethodsText: 'miscText.payments-empty-description',

	addFirst: 'miscText.payments-add-first',
	addAnother: 'miscText.payments-add-another',

	maxLimit: 'miscHtml.dashboard-addcard-maxlimit',
};

export const getPaymentMethodBreadcrumbs = () => [
	...getCharlieCardBreadcrumbs(),
	<BreadcrumbItem
		key={cms.header}
		cmsKey={cms.header}
		fallbackValue="Personal Information"
		routeKey={routeKeys.AccountPersonalInformation}
	>
	</BreadcrumbItem>,

];

const FundingSourceChip = ({
	billingAddress,
	fundingSource,
	cmsContent,
	handleChange,
}) => {
	const { formHelper } = useFormHelper({});
	const {
		creditCard: {
			maskedPan,
			cardExpiryMMYY,
		},
		status,
		setAsPrimary,
		nickname,
	} = fundingSource;

	useBreadcrumbCallback(getPaymentMethodBreadcrumbs);

	return (
		<div className={cx(panel.card, style.card)}>
			<div className={style.cardContent}>
				<div className={style.cardHeader}>
					<span className={panel.cardLogo}>
						{getCardIcon(fundingSource?.creditCard?.creditCardType, { overrideClass: panel.cardIcon })}

					</span>
					<span className={cx(panel.hideOnDesktop, style.cardName)}>
						{nickname
							? nickname
							: `${fundingSource?.creditCard?.creditCardType} (Personal)`}
					</span>
				</div>
				<div className={style.cardBody}>
					<span className={panel.hideOnMobile}>
						{nickname
							? nickname
							: `${fundingSource?.creditCard?.creditCardType} (Personal)`}
					</span>
					<div className={panel.cardValues}>
						<div className={panel.cardStat}>
							<CmsContentRenderer.Span
								className={panel.cardStatLabel}
								contentKey={cms.ending}
								fallbackValue="Ending"
							/>
							<span className={panel.cardStatValue}>...{maskedPan}</span>
						</div>
						<IsNotAlternate>
							<div className={panel.cardStat}>
								<CmsContentRenderer.Span
									className={panel.cardStatLabel}
									contentKey={cms.expires}
									fallbackValue="Expires"
								/>
								<span className={panel.cardStatValue}>
									{ccMonthYearDisplay(
										cardExpiryMMYY.slice(0, 2),
										cardExpiryMMYY.slice(2),
									)}
								</span>
							</div>
						</IsNotAlternate>
					</div>
					{status !== "Active" &&
						<TokenStatus
							overrideClass={style.statusText}
							frontendStatus={status}
							statusDescription={fundingSource.statusDescription}
						/>
					}
					{setAsPrimary &&
						<CmsContentRenderer.P
							contentKey={cms.default}
							fallbackValue="This is your default payment method"
							className={panel.cardStatValue}
						/>
					}
				</div>
				<div className={cx(panel.info, style.dropDown)}>
					<div className={panel.addressContainer}>
						<IsNotAlternate>
							<ManageMenu
								id={`payment${fundingSource.id}ManageMenu`}
								ariaLabel={cmsContent[ cms.manageAria ] || 'Manage this payment method'}
								options={[
									{
										label: <CmsContentRenderedInline
											contentKey={cms.manageEdit}
											fallbackValue="Edit"
											ariaLabel={cmsContent[ cms.manageEditAria ]}
										/>,
										action: () => handleChange({
											value: OPTION_EDIT,
											fundingSource,
											primaryBillingAddressId: billingAddress,
										}),
									},
									{
										type: TYPE_DANGER,
										label: <CmsContentRenderedInline
											contentKey={cms.manageDelete}
											fallbackValue="Delete"
											ariaLabel={cmsContent[ cms.manageDeleteAria ]}
										/>,
										action: () => handleChange({
											value: OPTION_REMOVE,
											fundingSource,
										}),
									},
								]}
							/>
						</IsNotAlternate>
					</div>
				</div>
			</div>
			<div className={style.errorContent}>
				{formHelper.getFieldErrorJsx('')}
			</div>
		</div>);
};

const PaymentMethods = () => {
	const history = useHistory();

	const { setModal } = useModalContext();
	const email = useStdQuery(GET_EMAIL)?.data?.session?.customer?.contact?.email ?? '';

	const location = useLocation();

	const {
		wsFundingSourceInfos,
		creditCardFundingSourceCountRemaining,
		loading,
	} = useFundingSources();

	const billingAddress = findPrimaryBillingAddressId(wsFundingSourceInfos);

	const handleChange = ({ value, fundingSource, primaryBillingAddressId }) => {
		switch (value) {
			case OPTION_EDIT:
				return setModal(
					<EditPaymentMethod
						fundingSource={fundingSource}
						primaryBillingAddressId={primaryBillingAddressId}
					/>,
				);
			case OPTION_REMOVE:
				setModal(
					<DeletePaymentMethodConfirmation wsFundingSourceInfo={fundingSource} />,
				);
		}
	};

	if (loading) {
		return <ComponentLoading />;
	}

	const consumeURLState = () => history.push({ pathname: location.pathname });

	return (
		<CmsContentList sharedVariables={{ email }} list={values(cms)}>{({ cmsContent }) => (
			<>
				{PublicAppVars.COMING_SOON_PAYMENT_METHODS && <ComingSoon/> }
				<AccountLayout title={findContentOrFallback(cmsContent, cms.header, "Payment Methods")}>
					<>
						{get(location, 'state.addPaymentSuccess', false)
							? <Toast
								type="success"
								text={<CmsContentRenderer rawHtml contentKey={cms.addedToastText}
									fallbackValue={`<p>New payment method added.<br>A confirmation message has been sent to ${email}.</p>`} />}
								onClosed={consumeURLState}
							/>
							: null}
						{Boolean(wsFundingSourceInfos.length > 0)
							? (
								<div className={style.panelWrapper}>
									<div className={panel.header}>
										<CmsContentRenderer.P
											className={cx(panel.label, style.statement)}
											data-qa="PaymentMethodsText"
											contentKey={cms.description}
											fallbackValue="Save your payment methods here to pay for Charlie products. Keep more than one on file if possible to address failed charges."
										/>
									</div>
									<div className={style.cardList}>
										{map(wsFundingSourceInfos, fundingSource =>
											<FundingSourceChip
												key={fundingSource.id}
												{...{
													fundingSource,
													billingAddress,
													cmsContent,
													handleChange,
												}} />)}
									</div>
									<IsNotAlternate>
										{Boolean(creditCardFundingSourceCountRemaining > 0)
											? <Button
												typeStyle={Primary}
												additionalClassNames={panel.isEmptyAction}
												to={getPathByRoute(routeKeys.AccountAddPaymentMethod)}
												data-qa="PaymentMethodsAction"
											>
												<CmsContentRenderer.Span
													contentKey={cms.addAnother}
													fallbackValue="Add a New Payment Method"
												/>
											</Button>
											: <InfoPanel>
												<CmsContentRenderer.Div
													rawHtml
													className={infoPanel.content}
													contentKey={cms.maxLimit}
													fallbackValue="<p>You've reached the maximum number of cards allowed on this account. To remove a card from your account, select the card then navigate to the Card Settings page.</p>"
												/>
											</InfoPanel>
										}
									</IsNotAlternate>
								</div>
							) : (
								<Panel overrideClass={panel.isEmpty}>
									<CmsContentRenderer.H3
										className={panel.subtitle}
										contentKey={cms.noMethodsHeading}
										fallbackValue="You Haven’t Added Payment Methods."
									/>
									<IsNotAlternate>
										<CmsContentRenderer.P
											className={style.text}
											data-qa="PaymentMethodsText"
											contentKey={cms.noMethodsText}
											fallbackValue="Add a payment method to pay for passes and reload your balance every month."
										/>
										<Button
											typeStyle={Primary}
											additionalClassNames={panel.isEmptyAction}
											to={getPathByRoute(routeKeys.AccountAddPaymentMethod)}
											data-qa="PaymentMethodsAction"
										>
											<CmsContentRenderer.Span
												contentKey={cms.addFirst}
												fallbackValue="Add A Payment Method"
											/>
										</Button>
									</IsNotAlternate>
								</Panel>
							)
						}
					</>
				</AccountLayout>
			</>
		)}</CmsContentList>);
};

export default PaymentMethods;
