import React from "react";
import PropTypes from 'prop-types';
import ServerDate from "components/ServerDate.js";
import { getCardExpiryDate } from 'components/card-icons/util.js';

export const TokenExpiryDate = ({
	tokenInfo,
	elementType: ElementType = "span",
	...rest
}) => {
	return (
		<ElementType {...rest}>
			<ServerDate
				dateISO={getCardExpiryDate(tokenInfo)}
				options={{ month: 'short', day: "numeric", year: 'numeric' }}
			/>
		</ElementType>
	);
};

TokenExpiryDate.propTypes = {
	elementType: PropTypes.elementType,
	tokenInfo: PropTypes.shape({
		tokenType: PropTypes.string,
		expirationYear: PropTypes.number,
		expirationMonth: PropTypes.number,
		cardExpiryDate: PropTypes.string,
	}).isRequired,
};

export default TokenExpiryDate;
