import React from 'react';
import PropTypes from "prop-types";
import StdQuery from 'components/data/StdQuery.js';
import {
	FUNDING_SOURCES_GET_QUERY,
} from 'graphql-queries/FundingSources.js';
import { filter } from 'lodash';
import useStdQuery from 'components/data/hooks/useStdQuery.js';

// We can only do a "soft" delete, payment methods just get assigned a deleted status.
// Filter out deleted cards.
export const filterDeleted = fundingSources => filter(fundingSources, wsFundingSourceExt => wsFundingSourceExt.status !== "Deleted");

export const useFundingSources = () => {
	const response = useStdQuery(FUNDING_SOURCES_GET_QUERY);

	const {
		fundingSources,
		fundingSourceCountRemaining,
		creditCardFundingSourceCountRemaining,
		directDebitFundingSourceCountRemaining,
		directDebitFundingSourceAttemptsCountRemaining,
	} = response?.data?.session ?? {};

	const wsFundingSourceInfos = filterDeleted(fundingSources);

	return {
		wsFundingSourceInfos,
		fundingSourceCountRemaining,
		creditCardFundingSourceCountRemaining,
		directDebitFundingSourceCountRemaining,
		directDebitFundingSourceAttemptsCountRemaining,

		...response,
	};
};

const FundingSources = ({
	children,
}) => {
	return (
		<StdQuery query={FUNDING_SOURCES_GET_QUERY}>
			{(queryResult) => {
				const { session: { fundingSources } } = queryResult.data;
				const wsFundingSourceInfos = filterDeleted(fundingSources);
				return children(wsFundingSourceInfos, queryResult);
			}}
		</StdQuery>
	);
};

FundingSources.propTypes = {
	children: PropTypes.func.isRequired,
};

FundingSources.defaultProps = {
};

export default FundingSources;
