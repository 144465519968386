import React, { useState } from "react";
import cx from "classnames";
import { Redirect } from 'react-router-dom';

import CmsContentList from 'components/data/CmsContentList.js';
import ArchiveToken from "components/account/card/token-actions/ArchiveToken.js";
import { getPlaceholderKey } from 'components/account/card/activate-card/ActivateCard.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import CmsContentRendered from 'components/data/CmsContentRendered.js';
import { getPathByRoute } from "App.js";
import routeKeys from 'CustomerRouteKeys.js';
import LoadingIcon, { SIZES } from 'components/icons/LoadingIcon.js';
import { useGlobalToastsContext } from 'context/ToastProvider';
import { Last4Digits } from 'components/account/card/TokenNameAndPan.js';
import { centsToDisplay } from 'utils/FormatHelpers.js';
import { TOKEN } from "utils/Constants.js";
import { TRAVEL_TOKEN_TYPES, openTransitTemporary, openTransitVirtual, virtualCard , openTransitRegular } from "server/api-types/WSSubsystemAccountTravelTokenDisplayFactory.js";
import { useModalContext } from "context/ModalProvider.js";
import PublicAppVars from "utils/PublicAppVars.js";

import CardLayout, { getCardLayoutBreadcrumbs } from 'layouts/CardLayout.js';
import Button, {
	buttonTypeStylePlain,
} from "components/Button.js";
import TokenStatus from 'components/account/card/TokenStatus.js';
import TokenExpiryDate from 'components/account/card/TokenExpiryDate.js';
import {
	CardImage,
	CharlieCard,
	CharlieCardInactive,
} from 'components/icons/CardBrands.js';
import EditCardNickname from "components/modals/EditCardNickname.js";
import MyGroups from 'components/account/panels/MyGroups.js';
import ResumeUseCard from 'components/modals/ResumeUseCard.js';
import ErrorAutoload from "components/modals/ErrorAutoload";

import * as panel from "components/Panel.module.css";
import * as settings from "./Settings.module.css";
import * as admin from 'layouts/Admin.module.css';
import * as typography from "styles/typography.module.css";

import CmsContentRenderedInline from "components/data/CmsContentRenderedInline";
import { useBreadcrumbCallback } from "context/BreadcrumbProvider";
import {
	isGhostCard,
	findPrimaryToken,
} from "components/manage-cards/TokenHelpers.js";
import { OPEN_TRANSIT_TEMPORARY_CARD, OPEN_TRANSIT_VIRTUAL_CARD } from "server/api-types/WSSubsystemAccountToken.js";

import { BYPASS_TO_REPLACEMENT } from "pages/account/card/BlockOrReplaceFlow.js";
import { STEP_OFFSET_PARAM } from "context/StepContext.js";
import {
	ACTION_TYPE_PARAM,
	REASON_TYPE_PARAM,
	RESOLUTION_TYPE_PARAM,
	replaceReasonMapping,
} from "pages/account/card/replacement/ReasonOptions.js";
import {
	CARD_ACTIONS,
	CARD_RESOLUTIONS,
	CARD_REASONS,
} from "pages/account/card/replacement/constants.js";
import Toast, { TYPE_ERROR } from "components/Toast.js";
import FormHelper from "utils/FormHelper.js";
import TerminateToken from "components/account/card/token-actions/TerminateToken.js";
import { useTransitAccountIdContext } from "context/TransitAccountIdContext.js";
import { useTransitAccount } from 'components/data/transit-account/TransitAccount.js';
import { useIsEmvCard } from "components/data/transit-account/EMV.helpers.js";
import PrimaryToken from "components/account/card/token-actions/PrimaryToken.js";


const cms = {
	header: "miscText.card-settings-header",
	description: "miscText.card-settings-description",
	typeStandard: "miscText.card-settings-type-standard",
	serialNumber: "miscText.card-settings-serialnumber",
	status: "miscText.card-settings-status",
	expiry: "miscText.card-settings-expiry",
	enablement: "miscText.card-settings-enablement",
	freeze: "miscText.card-settings-freeze-replace-cta",
	resume: "miscText.card-settings-unfreeze-cta",
	replace: "miscText.card-settings-replace-cta",
	terminate: "miscText.card-settings-terminate-cta",
	manageHeader: "miscText.card-settings-manage-subheader",
	nicknameCta: "miscText.card-settings-manage-nickname-cta",
	unlinkCta: "miscText.card-settings-manage-unlink-transit-cta",
	unlinkErrorHeader: "miscText.card-settings-manage-unlink-error-header",
	unlinkErrorAutoload: "miscHtml.card-settings-manage-unlink-error-autoload",
	unlinkErrorSubmit: "miscText.card-settings-manage-unlink-error-submit",
	upgradeBtn: "miscText.card-settings-upgrade-cta",

	removeCard: "miscText.card-settings-remove-cta",
	temporaryCard: "miscText.general-cardtype-temporary",
	toastRemoveCard: "miscText.card-settings-remove-confirmation",

	delinkMobileCardHeader: "miscText.card-settings-manage-unlink-error-header",
	delinkMobileCardText: "miscHtml.card-settings-manage-unlink-error-mobile",

	placeholderCharliePhysical: 'miscText["addcard-charlie-physical-nickname.placeholder"]',
	placeholderCharlieVirtual: 'miscText["addcard-charlie-virtual-nickname.placeholder"]',
	placeholderEmv: 'miscText["addcard-emv-nickname.placeholder"]',
	placeholderEmvWallet: 'miscText["addcard-wallet-emv-nickname.placeholder"]',
	placeholderAppleWallet: 'miscText["addcard-wallet-apple-nickname.placeholder"]',
};


const getTokenActions = ({
	tokenType,
	tokenId,
	isEmv,
	mediaType,
	statusReasonCode,
	status,
	frontendStatus,
	filteredTokens,
}) => {
	const primaryToken = findPrimaryToken(filteredTokens);

	const isPrimaryToken = tokenId === primaryToken.tokenId;
	const isTempCard = mediaType === OPEN_TRANSIT_TEMPORARY_CARD;

	// Tokens closed with the following reason codes cant be
	// terminated, replaced or made primary
	const isInActionableToken = PublicAppVars.INACTIONABLE_TOKEN_REASON_CODES.map(object => object.reasonCodeId).includes(parseInt(statusReasonCode, 10));

	const isMobileFareCard = PublicAppVars.MOBILE_FARE_CARD_TOKEN_MEDIA_TYPES.includes(mediaType);

	const canUnblock = status === TOKEN.STATUS.closed && isInActionableToken;
	const canReplace = !isEmv && (frontendStatus === TOKEN.STATUS.expired || canUnblock) && !isInActionableToken && !isMobileFareCard;

	//https://reflexions.atlassian.net/browse/MBTA-3126
	const isActionToTerminateOrRemove = mediaType === openTransitRegular
	|| mediaType === openTransitTemporary
	|| mediaType === openTransitVirtual
	|| mediaType === virtualCard;

	const canTerminate = canUnblock && !isInActionableToken && !isMobileFareCard && isActionToTerminateOrRemove;
	const canUpgrade = isTempCard && PublicAppVars.ENABLE_UPGRADE_CARD;
	const canBlock = tokenType === TRAVEL_TOKEN_TYPES.smartcard && frontendStatus === TOKEN.STATUS.active;

	// Any token can be made primary if it meets the following criteria:
	// 1. Closed
	// 2. Has a reasoncode of `-4200` or no reasonCode
	// 3. Not currently primary
	const canMakePrimary = status === TOKEN.STATUS.closed
	&& !isPrimaryToken
	&& (PublicAppVars.ACTIONABLE_TOKEN_RESONCODE_IDS.map(code => code.reasonCodeId).includes(parseInt(statusReasonCode, 10)) || !statusReasonCode);

	// To remove a token it must be:
	// 1. Terminated or Lost or Stolen
	// 2. Not the Primary Token
	// 3. There must be more than 1 token associated with the transitAccount
	const canArchiveToken = (isInActionableToken || (frontendStatus === TOKEN.STATUS.terminated))
	&& isActionToTerminateOrRemove
	&& !isPrimaryToken
	&& filteredTokens.length > 1;

	return {
		canUpgrade,
		canBlock,
		canUnblock,
		canReplace,
		canTerminate,
		canMakePrimary,
		canArchiveToken,
	};
};

// Card style by default has no border radius.
// The logic here determines where to apply border radius so all cards are contiguous with only top and bottom cards having a radius.
const getCardStyles = ({ isActive, isFirst, isLast }) =>
	cx(panel.card,
		isActive
			? null
			: panel.isNotActive,
		(isFirst && isLast)
			? panel.isOneCard
			: isFirst
				? panel.isFirst
				: isLast
					? panel.isLast
					: null,
	);

const TokenActions = ({
	filteredTokens,
	wsTransitAccountToken,
	onModalClose,
}) => {
	const subsystemAccountReference = useTransitAccountIdContext();
	const { setModal } = useModalContext();
	const [ error, setError ] = useState();

	const isEmv = useIsEmvCard({ subsystemAccountReference });

	const {
		mediaType,
		tokenId,
		frontendStatus,
		status,
		statusReasonCode,
		tokenInfo,
	} = wsTransitAccountToken;

	// https://reflexions.atlassian.net/browse/MBTA-2615
	// statusReasonCode should be an ID not a string - "Token Expired"
	// implement a workaround to handle the current reason
	const mappedStatusReasonCode = statusReasonCode === "Token Expired"
		? replaceReasonMapping[ CARD_REASONS.expired ].reasonCode
		: statusReasonCode;

	const argObj = {
		tokenId,
		tokenType: tokenInfo.tokenType,
		isEmv,
		mediaType,
		statusReasonCode,
		status,
		frontendStatus,
		filteredTokens,
	};

	const {
		canUpgrade,
		canBlock,
		canUnblock,
		canReplace,
		canTerminate,
		canMakePrimary,
		canArchiveToken,
	} = getTokenActions(argObj);

	const replaceRedirect = getPathByRoute(routeKeys.AccountCardBlockOrReplace, {
		transit_account_id: subsystemAccountReference,
		replacedTravelTokenId: tokenId,
	}) + `?${STEP_OFFSET_PARAM}=${BYPASS_TO_REPLACEMENT}&${REASON_TYPE_PARAM}=${mappedStatusReasonCode}&${ACTION_TYPE_PARAM}=${CARD_ACTIONS.replaceCard}&${RESOLUTION_TYPE_PARAM}=${CARD_RESOLUTIONS.newCard}`;

	return <>
		{error && FormHelper.errorJsx(error)}

		{canUpgrade && <div>
			<Button
				typeStyle={buttonTypeStylePlain}
				additionalClassNames={settings.cardAction}
				to={getPathByRoute(routeKeys.AccountCardUpgrade, { transit_account_id: subsystemAccountReference })}
			>
				<CmsContentRenderer.Span
					contentKey={cms.upgradeBtn}
					fallbackValue="Upgrade your Charlie Card"
				/>
			</Button>
		</div>}

		{canBlock && <div>
			<Button
				typeStyle={buttonTypeStylePlain}
				to={getPathByRoute(routeKeys.AccountCardBlockOrReplace, {
					transit_account_id: subsystemAccountReference,
					replacedTravelTokenId: tokenId,
				})}
				additionalClassNames={settings.cardAction}
			>
				<CmsContentRenderer.Span
					fallbackValue="Block or replace card"
					contentKey={cms.freeze}
				/>
			</Button>
		</div>}

		{canUnblock && <div>
			<Button
				typeStyle={buttonTypeStylePlain}
				onClick={() =>
					setModal(<ResumeUseCard {...{
						hasEnablementFee: (tokenInfo.tokenEnablementFee.feeDueAmount ?? 0) > 0,
						subsystemAccountReference,
						replaceRedirect,
						onModalClose,
						tokenId,
					}} />)
				}
				additionalClassNames={settings.cardAction}
			>
				<CmsContentRenderer.Span
					fallbackValue="Unblock card"
					contentKey={cms.resume}
				/>
			</Button>
		</div>}

		{canReplace && <div>
			<Button
				typeStyle={buttonTypeStylePlain}
				additionalClassNames={settings.cardAction}
				to={replaceRedirect}
			>
				<CmsContentRenderer.Span
					fallbackValue="Replace Card"
					contentKey={cms.replace}
				/>
			</Button>
		</div>}

		{canTerminate && <TerminateToken {...{ wsTransitAccountToken, setError }} />}
		{canArchiveToken && <ArchiveToken {...{ wsTransitAccountToken, setError }} />}
		{canMakePrimary && <PrimaryToken {...{ wsTransitAccountToken }} />}
	</>;
};

const Token = ({
	filteredTokens,
	onModalClose,
	wsTransitAccountToken,
	isFirst = true,
	isLast = true,
}) => {
	const {
		frontendStatus,
		statusReasonCodeDesc,
		tokenInfo,
	} = wsTransitAccountToken;

	const { tokenType } = tokenInfo;

	const isActive = frontendStatus === TOKEN.STATUS.active;

	const cardStyles = getCardStyles({ isActive, isFirst, isLast });

	const tokenCardType = tokenType === TRAVEL_TOKEN_TYPES.smartcard
		? isActive
			? CharlieCard
			: CharlieCardInactive
		: tokenInfo.creditCardType;

	return (
		<CmsContentList list={Object.values(cms)}>{() =>
			<div className={cardStyles}>
				<CmsContentRendered.H2
					fallbackValue="Standard Fare Card"
					contentKey={getPlaceholderKey(tokenInfo.tokenType)}
					variables={{ cardtype: tokenInfo.tokenType }}
					className={cx(typography.h8, panel.cardName)}
				/>
				<div className={settings.cardDetails}>
					<div>
						<CardImage
							creditCardType={tokenCardType}
							className={settings.cardImage}
							svgAriaDesc={tokenInfo.creditCardType}
							mobileWalletType={tokenInfo?.mobileWalletType}
							status={frontendStatus}
						/>
					</div>
					<div className={settings.cardDetailsContainer}>
						<div className={settings.cardInfo}>
							<CmsContentRenderer.Span
								className={settings.cardInfoLabel}
								contentKey={cms.serialNumber}
								fallbackValue="Serial Number"
							/>
							<span className={settings.cardInfoValue}>
								...<Last4Digits {...{ tokenInfo: wsTransitAccountToken.tokenInfo }} />
							</span>
						</div>
						<div className={settings.cardInfo}>
							<CmsContentRenderer.Span
								className={settings.cardInfoLabel}
								contentKey={cms.expiry}
								fallbackValue="Expires"
							/>
							<span className={settings.cardInfoValue}>
								<TokenExpiryDate tokenInfo={tokenInfo} />
							</span>
						</div>
						<div className={settings.cardInfo}>
							<CmsContentRenderer.Span
								className={settings.cardInfoLabel}
								contentKey={cms.status}
								fallbackValue="Status"
							/>
							<span className={settings.cardInfoValue}>
								<TokenStatus
									{...{ frontendStatus }}
									statusDescription={frontendStatus}
									statusReasonCodeDesc={statusReasonCodeDesc}
								/>
							</span>
						</div>
						{/* Only show if there is a fee attached */}
						{tokenInfo.tokenEnablementFee.feeDueAmount > 0 && (
							<div className={settings.cardInfo}>
								<CmsContentRenderer.Span
									className={settings.cardInfoLabel}
									fallbackValue="Enablement fee"
									contentKey={cms.enablement}
								/>
								<span className={settings.cardInfoValue}>
									{centsToDisplay(tokenInfo.tokenEnablementFee.feeDueAmount)}
								</span>
							</div>
						)}
					</div>
				</div>
				<TokenActions
					wsTransitAccountToken={wsTransitAccountToken}
					filteredTokens={filteredTokens}
					onModalClose={onModalClose}
				/>
			</div>
		}</CmsContentList>
	);
};


export const getCardSettingsBreadcrumbs = () => [
	...getCardLayoutBreadcrumbs(),
	<CmsContentRenderedInline
		key={cms.header}
		contentKey={cms.header}
		fallbackValue="Card Settings"
	/>,
];

const Settings = () => {
	useBreadcrumbCallback(getCardSettingsBreadcrumbs);
	const subsystemAccountReference = useTransitAccountIdContext();

	const { setToast, removeToast } = useGlobalToastsContext();
	const { setModal } = useModalContext();
	const [ redirect, setRedirect ] = useState(null);
	const onModalClose = () => setModal(null);

	const handleUnlinkCard = ({ isMobileFareCard, hasAutoloads, cmsContent }) => {
		// https://reflexions.atlassian.net/browse/MBTA-2446
		// MobileFare/OpenTransitVirtual cards can not be delinked. Instead we throw a Toast message.
		if (isMobileFareCard) {
			setToast(<Toast
				type={TYPE_ERROR}
				title={<CmsContentRenderer.Span
					cmsContent={cmsContent}
					contentKey={cms.delinkMobileCardHeader}
					fallbackValue={'You may not remove this card'}
				/>}
				text={
					<CmsContentRenderer.Span
						cmsContent={cmsContent}
						contentKey={cms.delinkMobileCardText}
						fallbackValue={'<p>To unlink virtual cards, you must contact customer service.</p>'}
					/>
				}
				onClosed={removeToast}
			/>);

			return;
		}

		hasAutoloads
			? setModal(<ErrorAutoload onModalClose={onModalClose} cmsContent={cmsContent} />)
			: setRedirect(<Redirect push
				to={{ pathname: getPathByRoute(routeKeys.DeLinkCard, { transit_account_id: subsystemAccountReference }) }} />);
	};

	const {
		data: transitAccountQData,
		loading: transitAccountQLoading,
	} = useTransitAccount({ subsystemAccountReference, subscriptions: true });

	if (transitAccountQLoading) {
		return <LoadingIcon size={SIZES.page} />;
	}

	if (redirect) {
		return redirect;
	}

	const {
		transitAccountQ: {
			tokens,
			subscriptions,
			riderClass,
		},
	} = transitAccountQData;

	const ghostCard = isGhostCard(transitAccountQData.transitAccountQ);
	const hasAutoloads = Boolean(subscriptions?.length);

	// Filter out archived tokens
	const filteredTokens = tokens.filter(token => token.frontendStatus !== TOKEN.STATUS.archive);

	const primaryToken = ghostCard
		? null
		: findPrimaryToken(filteredTokens);

	const isMobileFareCard = ghostCard
		? null
		: primaryToken.tokenInfo.tokenType === OPEN_TRANSIT_VIRTUAL_CARD;

	return (
		<CmsContentList list={Object.values(cms)}>{({ cmsContent }) =>
			<>
				<CardLayout title={<CmsContentRenderer contentKey={cms.header} fallbackValue="Charlie Card Settings" />}>
					<CmsContentRenderer.P
						className={settings.settingsTitle}
						contentKey={cms.description}
						fallbackValue="You can use your Charlie Card in different ways depending on what's most convenient for you."
					/>
					<div className={settings.container}>
						<div className={admin.pageCol}>
							<div className={settings.cardList}>
								{ghostCard
									? null
									: filteredTokens?.map((wsTransitAccountToken, index) =>
										<Token
											isFirst={index === 0}
											isLast={index + 1 === filteredTokens.length}
											onModalClose={onModalClose}
											key={wsTransitAccountToken.id}
											filteredTokens={filteredTokens}
											isPrimaryToken={wsTransitAccountToken.id === primaryToken.id}
											subsystemAccountReference={subsystemAccountReference}
											wsTransitAccountToken={wsTransitAccountToken}
											{...{ riderClass }}
										/>
									)}
							</div>
							{ghostCard
								? null
								: <div className={settings.subContainer}>
									<CmsContentRenderer.H3
										contentKey={cms.manageHeader}
										fallbackValue='Manage Card'
										className={admin.subTitle}
									/>
									<Button
										typeStyle={buttonTypeStylePlain}
										additionalClassNames={settings.cardAction}
										onClick={() =>
											setModal(
												<EditCardNickname
													tokenNickname={primaryToken?.tokenInfo.tokenNickname}
													tokenId={primaryToken?.id}
													transitAccountId={subsystemAccountReference} />
											)}
									>
										<CmsContentRenderer.Span
											contentKey={cms.nicknameCta}
											fallbackValue='Edit Card Nickname'
										/>
									</Button>
								</div>}
							<div>
								<Button
									className={settings.dangerUnlink}
									onClick={() => handleUnlinkCard({
										hasAutoloads,
										cmsContent,
										isMobileFareCard,
									})}
								>
									<CmsContentRenderer.Span
										fallbackValue='Unlink This Card From Your Account.'
										contentKey={cms.unlinkCta}
									/>
								</Button>
							</div>
						</div>
						<div className={admin.pageCol}>
							{PublicAppVars.ENABLE_TOKEN_GROUPS ? <MyGroups /> : null}
						</div>
					</div>
				</CardLayout>
			</>
		}</CmsContentList>
	);
};

export default Settings;
