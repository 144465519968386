/**
 * Generates a table using React-Table for displaying various amounts of data.
 * Sorting can be enabled via a prop.
 */
import React, {
	useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
	useExpanded,
	useFilters,
	useRowSelect,
	useSortBy,
	usePagination,
	useTable,
} from 'react-table';
import qs from 'qs';
import Collapse from '@kunukn/react-collapse';
import cx from 'classnames';
import {
	find,
	isArray,
	isUndefined,
	join,
	map,
	values,
	clone,
	forEach,
} from 'lodash';
import PreventDefault from 'utils/PreventDefault.js';
import CmsContentRenderer, { findContentOrFallback } from 'components/data/CmsContentRenderer.js';
import CmsContentRenderedInline from "components/data/CmsContentRenderedInline.js";
import CmsContentList from 'components/data/CmsContentList.js';
import { SIZES } from 'components/icons/LoadingIcon.js';
import { isFailedPaymentStatus } from 'server/api-types/WSPaymentHistoryInfo.js';
import PublicAppVars from "utils/PublicAppVars.js";
import {
	isAutoFilledTap,
	isMissedTap,
	getTripStatusCmsKeyValue,
} from 'utils/TripHistory.js';

import TableRowActions from 'components/account/data-tables/TableRowActions.js';
import PurchaseHistoryMobileRow from 'components/account/data-tables/PurchaseHistoryMobileRow.js';
import Button, { buttonTypeStylePlain } from 'components/Button.js';
import { Chevron } from 'components/icons/UI.js';
import Download from 'components/icons/ui/Download.js';
import Print from 'components/icons/ui/Print.js';
import { types } from 'server/api-types/WSPaymentHistoryFactory.js';
import {
	RejectedTap,
	InspectionTap,
	UnmatchedTap,
} from "server/api-types/WSRideHistoryFactory.js";
import { PDF_TYPES } from './account/download-reports/pdf/Document.js';
import Pagination from './Pagination.js';
import {
	ORDER_NUMBER,
	PURCHASED_ON,
	PURCHASED_AT,
	PRODUCTS,
	REFUND_GIVEN,
	getSubRows,
} from 'components/account/data-tables/PurchaseHistoryTable.js';
import {
	TRANSIT_PRODUCT,
	FARE_DESCRIPTIONS,
	getSortedLineItems,
} from 'components/account/data-tables/TripHistoryTable.js';

import {
	getPurchaseRefundStatusCmsKeyValue,
} from 'utils/PurchaseHistory.js';

import * as collapse from 'components/Collapsible.module.css';
import * as checkbox from 'components/forms/Checkbox.module.css';
import * as tables from 'components/DataTable.module.css';
import {
	MQ_TABLET,
	useMediaQueryMatches,
} from "utils/Breakpoints.js";


export const TABLE_PURCHASES = tables.purchasesTable;
export const TABLE_TRAVEL = tables.travelTable;


// TODO add cms keys
const cms = {
	// purchase table
	colDate: 'miscText.purchasehistory-table-date',
	colLocation: 'miscText.purchasehistory-table-location',
	colType: 'miscText.purchasehistory-table-type',
	colPurchaseProduct: 'miscText.purchasehistory-table-product',
	colAmount: 'miscText.purchasehistory-table-amount',
	colMethod: 'miscText.purchasehistory-table-payment-method',
	colExpand: 'miscText.general-table-header-expandable-aria',

	expandRowAria: 'miscText.general-table-row-expand-aria',
	collapseRowAria: 'miscText.general-table-row-collapse-aria',

	multipleProducts: 'miscText.purchasehistory-product-multi-product',
	startsOn: 'miscText.cardinfo-pass-starts',
	multipleRides: 'miscText.purchasehistory-product-multi-ride',

	reload: 'miscText.purchasehistory-type-reload',
	autoload: 'miscText.purchasehistory-type-autoload',
	refund: 'miscText.purchasehistory-type-refund',
	directPay: 'miscText.triphistory-product-directpay',
	balance: 'miscText.purchasehistory-product-balance',

	printPurchaseFiltered: 'miscText.purchasehistory-printall',
	printPurchaseAllArea: 'miscText.purchasehistory-printall-aria',

	paymentDeclined: 'miscText.purchasehistory-product-declined',
	disputeWithStatus: 'miscText.purchasehistory-dispute-status',

	orderNumber: 'miscText.purchasehistory-moreinfo-ordernum',
	purchasedOn: 'miscText.purchasehistory-moreinfo-when',
	purchasedAt: 'miscText.purchasehistory-moreinfo-where',
	products: 'miscText.purchasehistory-moreinfo-products',
	refundGiven: 'miscText.purchasehistory-moreinfo-refund',

	// trips table
	colTime: "miscText.triphistory-table-time",
	colMode: "miscText.triphistory-table-mode",
	colTripProduct: "miscText.triphistory-table-product",
	colFare: "miscText.triphistory-table-fare",
	fareDescription: 'miscText.triphistory-table-fare-description',

	productDeclined: 'miscText.triphistory-product-declined',
	declinedTooltip: 'miscText.triphistory-product-declined-tooltip',
	expiredReason: 'miscText.triphistory-product-declined-3',
	riskReason: 'miscText.triphistory-product-declined-4',
	passbackReason: 'miscText.triphistory-product-declined-5',
	badBinReason: 'miscText.',
	unknownCardReason: 'miscText.',
	notValidReason: 'miscText.',

	autoResolvedTap: 'miscText.triphistory-trip-autocorrected-tap',
	autocorrectedTooltip: 'miscHtml.triphistory-trip-autocorrected-tap-tooltip',
	missedTap: 'miscText.triphistory-trip-missed-tap',
	missedTapTooltip: 'miscHtml.triphistory-trip-missed-tap-tooltip',
	fareMissedTap: 'miscText.triphistory-trip-missed-tap-fare',
	oldMissedTap: 'miscText.triphistory-trip-oldmissed-tap',

	inspection: 'miscText.triphistory-product-inspection',
	warningNotice: 'miscText.triphistory-product-inspection-notice-warning',
	citationNotice: 'miscText.triphistory-product-inspection-notice-citation',
	surchargeNotice: 'miscText.triphistory-product-inspection-notice-surcharge',
	surchargeTooltip: 'miscText.triphistory-product-inspection-notice-surcharge-tooltip',
	penaltyFareNotice: 'miscText.',

	printTripsFiltered: 'miscText.triphistory-print',
	printTripsAllArea: 'miscText.triphistory-print-aria',

	// general
	downloadPdf: 'miscText.dataTables-actions-downloadPdf',
	downloadCsv: 'miscText.dataTables-actions-downloadCsv',

	sortAsc: 'miscText.dataTables-aria-sortAscending',
	sortDesc: 'miscText.dataTables-aria-sortDescending',
	sortAscLabel: 'miscText.general-table-sort-ascending',
	sortDesclabel: 'miscText.general-table-sort-descending',
};


/**
 * Used to create a custom columns for selecting one, multiple or all rows
 */
const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [ resolvedRef, indeterminate ]);

		return <>
			<input type="checkbox" ref={resolvedRef}
				className={cx(checkbox.input)}
				{...rest}
			/>
		</>;
	},
);
IndeterminateCheckbox.displayName = "IndeterminateCheckbox";



/**
 * Returns a class string to apply to the Status column based on that value
 *
 * @param {string} status
 * @returns string
 */
export const getStatusStyle = (status) => {
	switch (status) {
		case 'Canceled':
		case 'ProcessingFailed':
			return tables.isError;

		case 'Processing':
		case 'Shipped':
		case 'Unpaid':
		case 'Partially Paid':
			return tables.isWarning;

		case 'Complete':
		case 'Paid':
			return tables.isSuccess;

		default:
			return tables.isSuccess;
	}
};



/**
 * Adds the sortable arrows to a column header
 */
const TableSort = ({
	order = "asc",
	cmsContent,
}) => {
	return (
		<div className={tables.sort}>
			<button
				className={cx(tables.triangle, tables.up, order === 'asc' && tables.isActive)}
				aria-label={cmsContent[ cms.sortAscLabel ] ?? 'Sort by ascending'}
				title={cmsContent[ cms.sortAsc ]}
			/>
			<button
				className={cx(tables.triangle, tables.down, order === 'desc' && tables.isActive)}
				aria-label={cmsContent[ cms.sortDesclabel ] ?? 'Sort by descending'}
				title={cmsContent[ cms.sortDesc ]}
			/>
		</div>
	);
};

export const displayPrintAll = ({
	cmsContent,
	isPurchaseTable = false,
	transitAccountId,
	filtersApplied,
}) =>  (
	<Button typeStyle={buttonTypeStylePlain}
		additionalClassNames={tables.tableAction}
		aria-label={cmsContent[ isPurchaseTable ? cms.printPurchaseAllArea : cms.printTripsAllArea ]}
		to={`/download-pdf/${isPurchaseTable ? PDF_TYPES.purchases : PDF_TYPES.trips}?${qs.stringify({ transitAccountId, ...filtersApplied }, { skipNulls: true })}`}
		target="_blank"
		external
	>
		<i className={tables.tableActionIcon}><Print /></i>
		<CmsContentRenderedInline contentKey={isPurchaseTable ? cms.printPurchaseFiltered : cms.printTripsFiltered}
			fallbackValue={isPurchaseTable ? "Print filtered purchase history" : "Print current filtered trip history"}
		/>
	</Button>
);


export const getTranslatedHeaderValue = (subHeaderValue) => {
	switch (subHeaderValue) {
		case ORDER_NUMBER:
			return <CmsContentRenderedInline contentKey={cms.orderNumber}
				fallbackValue={ORDER_NUMBER}
			/>;
		case PURCHASED_ON:
			return <CmsContentRenderedInline contentKey={cms.purchasedOn}
				fallbackValue={PURCHASED_ON}
			/>;
		case PURCHASED_AT:
			return <CmsContentRenderedInline contentKey={cms.purchasedAt}
				fallbackValue={PURCHASED_AT}
			/>;
		case PRODUCTS:
			return <CmsContentRenderedInline contentKey={cms.products}
				fallbackValue={PRODUCTS}
			/>;
		case REFUND_GIVEN:
			return <CmsContentRenderedInline contentKey={cms.refundGiven}
				fallbackValue={REFUND_GIVEN}
			/>;
		case TRANSIT_PRODUCT:
			return <CmsContentRenderedInline contentKey={cms.colTripProduct}
				fallbackValue={TRANSIT_PRODUCT}
			/>;
		case FARE_DESCRIPTIONS:
			return <CmsContentRenderedInline contentKey={cms.fareDescription}
				fallbackValue={TRANSIT_PRODUCT}
			/>;
		default:
			return;
	}
};

export const SubRowsHeaders = ({
	row,
	isPurchaseTable = false,
	subRowHeaders,
	subRows,
}) => {
	// Uses 'subRowHeaders' if provided, otherwise uses the keys of the first object in subRows.
	// subRowHeaders is useful when you need to render a node as the column header rather than a string.
	// For example if you need a <Tooltip /> to render in the column header, pass a subRowHeaders object.
	const headers = Object.keys(subRowHeaders) ?? Object.keys(subRows[ 0 ]);

	return (<div className={isPurchaseTable ? tables.purchaseDetailLabels : tables.tripDetailsLabels} role="row">
		{map(headers, (subHeaderValue, colIndex) => {
			const curSubHeaderValues = subRows[ 0 ][ subHeaderValue ];
			return (<div key={`sub-row-${row}-${colIndex}`}>
				{(isArray(curSubHeaderValues) && curSubHeaderValues?.length > 1)
					? <div role="cell">
						{getTranslatedHeaderValue(subHeaderValue)}
						{map(curSubHeaderValues.slice(1), (emptyCell, index) =>
							/*
								we have multiple products rendered in several rows, and below
								the products we should display "refund" row the refund amount
								should be displayed next to refund, but it moves on the bottom
								if multiple products
							*/
							<div
								key={`row${row.index}-col${colIndex}-${index}`} className={tables.blank}>
							</div>
						)}
					</div>
					: <div role="cell" key={`row${row.index}-col${colIndex}`} className={tables.isSubRowColumn}>
						{getTranslatedHeaderValue(subHeaderValue)}
					</div>
				}
			</div>);
		})}
	</div>);
};

const applySubRowCellStyles = ({
	isTravelMode = false,
}) => {
	if (isTravelMode) {
		return tables.productTripInfo;
	} else {
		return tables.productInfo;
	}
};

const generateNestedKey = (uniqueID, level, index) => {
	let key = uniqueID;
	for (let l = 0; l < level; l++) {
		key += `_${l}`;
	}
	return `${key}_${index}`;
};

export const SubRowsCells = ({
	row,
	subRows,
	isTravelMode = false,
	isPurchaseTable = false,
}) => {
	const uniqueId = row.original?.id ?? row?.id;

	return map(subRows, (subRow, cellIndex) =>
		<div
			role="row"
			key={generateNestedKey(uniqueId, 0, cellIndex)}
			className={isPurchaseTable ? tables.purchaseDetailValues : tables.tripDetailsValues}
		>
			{map(Object.keys(subRow), (headerKey, colIndex) =>
				<div role="cell" key={generateNestedKey(uniqueId, 1, colIndex)}
					className={cx(tables.subrowCell, (isTravelMode && row.index === 0) ? tables.fontWeightBold : '')}
				>
					{isArray(subRow[ headerKey ])
						? map(subRow[ headerKey ], (product, productIndex) => {
							const productValues = Object.values(product);
							return (
								<div key={generateNestedKey(uniqueId, 2, productIndex)}
									className={applySubRowCellStyles({ isTravelMode })}>
									{map(productValues, (productValue, productValueIndex) =>
										<div
											key={generateNestedKey(uniqueId, 3, productValueIndex)}
											className={cx(isTravelMode && productValues.length > 2 ? tables.productTripItem : tables.productItem)}
										>
											{productValue}
										</div>
									)}
								</div>
							);
						})
						: subRow[ headerKey ]
					}
				</div>
			)}
		</div>
	);
};

// search rows with statuses to expand them by default when initial render page
const getExpandedRows = (tableData, isPurchaseTable) => {
	const expandedRows = {};
	forEach(tableData, (value, key) => {
		if (isPurchaseTable) {
			if (Boolean(getPurchaseRefundStatusCmsKeyValue(value.orderRefundStatus))) {
				expandedRows[ key ] = true;
			}
		} else {
			if (Boolean(getTripStatusCmsKeyValue(value))) {
				expandedRows[ key ] = true;
			}
		}
	});
	return expandedRows;
};


const Headers = ({ headerGroups, isPurchaseTable, isPdfView, cmsContent }) => {
	return headerGroups.map((headerGroup, headerGroupIndex) => (
		<div key={`headerGroup-${headerGroupIndex}`}
			{...headerGroup.getHeaderGroupProps()}
			className={cx(
				isPurchaseTable
					? tables.headerRowPurchaseTable
					: tables.headerRow,
				isPdfView && (tables.displayOnMobile, tables.pdfView))}
			role="row">
			{headerGroup.headers.map((column, headerIndex) =>
				<div key={`header-${headerIndex}`}
					{...column.getHeaderProps(column.getSortByToggleProps())}
					className={tables.columnHeader}
					role="columnheader"
				>
					{column.render('Header', { cmsContent })}

					{/* needed to split canSort and isSorted bc it was adding TableSort all the time */}
					{column.canSort && !isPdfView
						? column.isSorted && column.isSortedDesc
							? <TableSort order="desc" {...{ cmsContent }} />
							: <TableSort {...{ cmsContent }} />
						: null
					}
				</div>
			)}
		</div>
	));
};


const applyCellStyles = ({
	isAutofilled = false,
	isMissed = false,
	isPaymentDeclined = false,
	cellId,
}) => {
	const classes = [
		tables.cell,
	];

	if (isPaymentDeclined && (cellId === 'amount' || cellId === 'paymentDetails')) {
		join(classes, tables.cellDeclined);
	}

	if ((isAutofilled && cellId === 'travelMode') || (isMissed && cellId === 'travelMode')) {
		join(classes, tables.cellAutofilled);
	}

	return classes;
};

const Rows = ({
	isPurchaseTable,
	page,
	prepareRow,
	isPdfView,
	isTripsTable,
	selectableRows,
	selectedRowIds,
	generateSubRows,
	expandableRows,
	cmsContent,
	filtersApplied,
}) => {
	const isAtLeastTablet = useMediaQueryMatches(MQ_TABLET);

	return 	page.map((row,index) => {
		prepareRow(row);

		const clonedRow = clone(row.original);
		// Only look for payment data if on Purchases Table
		const {
			payments,
			type,
		} = row.original;

		// need to handle multiple payments if Boston will accept split payment
		const { paymentStatus } = !isUndefined(payments) ? payments[ 0 ] : false;
		const isPaymentDeclined = isPurchaseTable ? isFailedPaymentStatus(paymentStatus) : false;
		const isTravelMode = isPurchaseTable ? type === types.travel : false;

		return <React.Fragment key={`row-${index}`}>
			<div {...row.getRowProps()}
				className={cx(tables.rowWrapper,
					!isPdfView && tables.sideBorders,
					row.isExpanded && tables.isExpanded,
					(isPurchaseTable && isPaymentDeclined) && tables.isDeclined,
				)}
				data-key={row.index}
				role="rowgroup"
				key={row.index}
			>
				{isPurchaseTable && isPaymentDeclined &&
					<CmsContentRenderedInline
						contentKey={cms.paymentDeclined}
						className={tables.paymentDeclined}
						fallbackValue="Payment Declined"
					/>
				}
				<div role="row" key={`rowId_${row.index}`}
					className={cx(isPurchaseTable && !isPdfView ? tables.innerRowPurchaseTable : tables.innerRow, selectableRows && tables.isSelectable,
						find(Object.keys(selectedRowIds), `${row.index}`) && tables.isSelected,
						isTripsTable && tables.travelTable,
					)}>
					{map(row.cells, (cell, index) =>
						<div
							role="cell"
							key={`${index}_${row.index}`}
							className={applyCellStyles({
								isPaymentDeclined,
								cellId: cell.column.id,
								isAutofilled: isAutoFilledTap(row),
								isMissed: isMissedTap(row),
							})}
							{...cell.getCellProps()}
						>
							{cell.render('Cell', { cmsContent })}
						</div>
					)}
				</div>

				{expandableRows ?
					<div key={`expander_${row.index}`} role="row">
						<Collapse
							isOpen={row.isExpanded}
							className={collapse.slide}
							overflowOnExpanded
						>
							<div role="cell" className={cx(tables.expandedRowWrapper,
								row.isExpanded && tables.isExpanded,
								(isPurchaseTable && isPaymentDeclined) && tables.isDeclined,
							)}>
								<div role="table" tabIndex="0">
								 {generateSubRows({
										row,
										hasPurchaseInfo : isPurchaseTable || (isTripsTable && !isAtLeastTablet),
										isTravelMode,
										isPurchaseTable,
									})}
								</div>
								<TableRowActions {...{
									isPurchaseTable: isPurchaseTable,
									filtersApplied,
									clonedRow,
								}} />

							</div>
						</Collapse>
					</div>
					: null
				}
			</div>
		</React.Fragment>;
	});
};



const getRiderDescription = (row) => row?.original?.riderClassDescription ? row?.original?.riderClassDescription : null;

/**
 * Generates the content needed for expanded row details on the PurchaseHistoryTable
 * @param {Object} row Row object containing subRows[] and an optional subRowHeaders obj
 */
const generateSubRows = ({
	row,
	isTravelMode = false,
	isPurchaseTable = false,
	hasPurchaseInfo = false,
}) => {

	// Purchase Table
	if (hasPurchaseInfo) {
		const subRowsValue = getSubRows(row.original ?? row);

		const { subRowHeaders, subRows } = isPurchaseTable
			? { subRowHeaders: subRowsValue,
				subRows: [ subRowsValue ] }
			: row.original;

		return (
			<div className={isPurchaseTable ? tables.purchaseDetails : tables.tripDetails} role="rowgroup">
				<SubRowsHeaders {...{ row, subRowHeaders, subRows, isPurchaseTable }} />
				<SubRowsCells {...{ row, subRows, isTravelMode, isPurchaseTable }} />
			</div>
		);
	}

	// Ride History Table
	const riderDescription = getRiderDescription(row);

	if (!riderDescription) {
		return null;
	}

	// We want this code to respect the same table grid-template as the styles above it.
	const cells = [];
	for (var i = 0; i < row.cells.length; i++) {
		if (i === 3) {
			cells.push(<div role="cell" className={tables.riderClassDescription}>{riderDescription}</div>);
		}
		else {
			cells.push(<div role="cell"></div>);
		}
	}

	return <div className={tables.innerRow}>
		{cells.map((c,index) =>
			<React.Fragment key={index}>{c}</React.Fragment>
		)}
	</div>;
};


const DataTable = ({
	tableType = TABLE_TRAVEL,
	tableColumns,
	tableData,
	itemsCountPerPage = PublicAppVars.TRIPS_HISTORY_PAGE_SIZE,
	totalCount,
	activePage,
	setActivePage,
	isUnregistered = false,
	fetchData,
	loading,
	pageCount: controlledPageCount = -1,
	showFilters = false,
	isSortable = true,
	showPagination = true,
	selectableRows = false,
	expandableRows = false,
	canDownload = false,
	canPrint = true,
	footer = null,
	transitAccountId = '',
	isPdfView = false,
	filtersApplied,
	additionalTableClassNames = '',

	// Callback for row selection
	onSelection = null,

	// Callback for clear selection
	onClearSelections = null,

	// Set to true to disable selectable rows
	disabledSelectables = false,
}) => {
	const isPurchaseTable = tableType === TABLE_PURCHASES;
	const isTripsTable = tableType === TABLE_TRAVEL;


	const defaultColumn = useMemo(
		() => ({
			// minWidth: 30, // minWidth is only used as a limit for resizing
			width: 150, // width is used for both the flex-basis and flex-grow
			// maxWidth: 200, // maxWidth is only used as a limit for resizing
		}),
		[],
	);

	const {
		// Standard
		getTableProps,
		getTableBodyProps,
		getCellProps,
		headerGroups,
		rows,
		prepareRow,

		// Row Selection
		selectedFlatRows,

		// Pagination
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,

		state: {
			expanded,
			selectedRowIds,
			pageIndex,
			pageSize,
		},
	} = useTable(
		{
			columns: tableColumns,
			defaultColumn,
			data: tableData,
			expandSubRows: false,
			initialState: {
				expanded: getExpandedRows(tableData),
				pageIndex: 0,
				manualPagination: true,
				pageCount: controlledPageCount,
				pageSize: isPdfView ? Number.MAX_SAFE_INTEGER : PublicAppVars.TRIPS_HISTORY_PAGE_SIZE,
				sortBy: !isPdfView ? [
					{
						id: tableColumns[ 0 ].accessor,
						desc: true,
					},
				] : [],
			},
		},
		useFilters,
		useSortBy,
		useExpanded,
		usePagination,
		useRowSelect,
		hooks => {
			// Adds a checkbox column as the first column
			if (selectableRows) {
				hooks.visibleColumns.push(tableColumns => [
					// Let's make a column for selection
					{
						id: 'selection',
						// The header can use the table's getToggleAllRowsSelectedProps method
						// to render a checkbox
						Header: ({ getToggleAllRowsSelectedProps }) => (
							<div>
								<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} disabled={disabledSelectables} />
							</div>
						),
						width: 25,
						// The cell can use the individual row's getToggleRowSelectedProps method
						// to the render a checkbox
						Cell: ({ row, selectedFlatRows }) => {
							const onClickHandler = () => {
								if (selectedFlatRows.length === 1 && row.isSelected) { // last selected row is unchecked
									onClearSelections();
								}
								const { onChange } = row.getToggleRowSelectedProps(); // default check action behavior, applys to all "check" interactions
								onChange();
							};
							return (
								<div>
									<IndeterminateCheckbox { ...row.getToggleRowSelectedProps() } disabled={disabledSelectables}  onClick={onClickHandler} />
								</div>
							);
						},
					},
					...tableColumns,
				]);
			}

			// Add the row expander toggle as the last column
			if (expandableRows) {
				hooks.visibleColumns.push(tableColumns => [
					...tableColumns,
					{
						id: 'expander',
						disableSortBy: true,
						// ADA audit purpose: hide the blank header cell, but remain accessible for
						// the keyboard event to announce <div> parent aria-label
						Header: ({ cmsContent }) => (
							<span style={{ opacity: 0, height: 0 }}>
								{findContentOrFallback(
									cmsContent,
									cms.colExpand,
									'More info')
								}
							</span>
						),
						Cell: ({ row, cmsContent }) => (
							<Button {...row.getToggleRowExpandedProps({
								// Overwriting default react-table expander title, since we add custom aria-label translation
								title: '',
							})}
							typeStyle={buttonTypeStylePlain}
							tabIndex="0"
							additionalClassNames={cx(tables.rowExpander,
								row.isExpanded && tables.isOpen,
								(isTripsTable && (row.original.type === RejectedTap
											|| row.original.type === InspectionTap)
										|| row.original.type === UnmatchedTap) && tables.hidden)}
							aria-label={findContentOrFallback(
								cmsContent,
								row.isExpanded
									? cms.collapseRowAria
									: cms.expandRowAria,
								row.isExpanded
									? 'Collapse row'
									: 'Expand row',
							)}
							>
								<Chevron width={10} />
							</Button>
						),
					},
				]);
			}
		},
	);

	const tableClasses = cx(tables.table, tableType,
		showFilters && tables.filtersVisible,
		isSortable && tables.isSortable,
		selectableRows && tables.isSelectable,
		additionalTableClassNames,
		isPdfView && tables.pdfView
	);

	if (selectableRows) {
		if (Object.keys(selectedRowIds).length > 0) {
			const selectedRows = map(selectedFlatRows, d => d.original);
			onSelection(selectedRows);
		}
	}

	return (
		<CmsContentList list={values(cms)} spinnerSize={SIZES.component}>{({ cmsContent }) => (
			<>
				<div {...getTableProps()}
					role="table"
					tabIndex="0"
					id={tableType}
					className={tableClasses}
				>
					<Headers
						{ ...{ headerGroups, isPurchaseTable, isPdfView, cmsContent }}
					/>
					<div {...getTableBodyProps()}
						role="rowgroup"
						className={cx(isPurchaseTable && !isPdfView ? (tables.displayDesktop, tables.pdfView): null)}
					>
						<Rows
							{...{
								isPurchaseTable,
								page,
								prepareRow,
								isPdfView,
								isTripsTable,
								selectableRows,
								selectedRowIds,
								generateSubRows,
								expandableRows,
								cmsContent,
								filtersApplied,
							}}
						/>
					</div>
					{isPurchaseTable && !isPdfView
						? <div role="rowgroup"
							className={tables.displayMobile}>
							{map(getSortedLineItems(tableData), (purchase, index) => {
								// set up row index for further purchase details mapping
								purchase.index = index;
								return (
									<PurchaseHistoryMobileRow key={purchase.paymentRefNbr} {...{ purchase, transitAccountId }} />
								);}
							)}
						</div>
						: null}

					{footer ?
						<div className={tables.footer}>
							{footer}
						</div>
						: null
					}

				</div>
				<div className={tables.tableActions}>
					<div className={tables.printDownload}>
						{canPrint && !isPdfView &&
							displayPrintAll({
								cmsContent,
								isPurchaseTable,
								transitAccountId,
								filtersApplied })}

						{canDownload &&
							<div className={tables.downloads}>
								<Button typeStyle={buttonTypeStylePlain}
									additionalClassNames={tables.tableAction}
									onClick={PreventDefault(() => alert('Download modal'))}
								>
									<i className={tables.tableActionIcon}><Download /></i>

									<CmsContentRenderer.Span
										contentKey={cms.downloadPdf}
										fallbackValue="Download PDF"
									/>
								</Button>
							</div>
						}
					</div>

					{showPagination ?
						<div className={tables.pagination}>
							<Pagination
								{...{ activePage, setActivePage,totalCount, itemsCountPerPage }}
								// Range of pages in paginator, exclude navigation blocks (prev, next, first, last pages)
								pageRangeDisplayed={3}
							/>
						</div>
						: null
					}
				</div>
			</>)}</CmsContentList>
	);
};

DataTable.propTypes = {
	tableColumns: PropTypes.arrayOf(PropTypes.object),

	tableData: PropTypes.arrayOf(PropTypes.object),

	isSortable: PropTypes.bool,

	showPagination: PropTypes.bool,

	selectableRows: PropTypes.bool,

	expandableRows: PropTypes.bool,

	canDownload: PropTypes.bool,

	canPrint: PropTypes.bool,

	footer: PropTypes.element,
};

export default DataTable;
