import React from 'react';
import CmsContentRenderer, { getContentOrFallback } from "components/data/CmsContentRenderer.js";
import * as style from "pages/account/card/TransferProducts.module.css";
import { filter, find, head, isEmpty, map, uniqBy, values } from "lodash";
import Select from "components/forms/inputs/Select.js";
import { getTokenNicknameAndPan } from "components/manage-cards/TokenHelpers.js";
import CmsContentList, { useTranslationByListResult } from "components/data/CmsContentList.js";
import LoadingIcon, { SIZES } from "components/icons/LoadingIcon.js";

const cms = {
	transferToLabel: 'miscText.transfer-select-target',
	transferNoOptions: 'miscText.transfer-select-target-error-empty',
};

const getContentRequestForManagedCustomerLabel = ({ linkId, firstName }) => ({
	key: 'miscText.transfer-select-target-owner',
	isTemplate: true,
	customId: linkId,
	variables: {
		name: firstName,
	},
});

const TransferTargetCardSelector = ({ travelCards, cart, formHelper, setTargetCard }) => {
	const managedCustomerLinkInfos = uniqBy(filter(map(travelCards, 'managedCustomerLinkInfo')), 'linkId');

	const { data: { translationByList: managedCustomerLabels } = {}, loading } = useTranslationByListResult({
		list: map(managedCustomerLinkInfos, getContentRequestForManagedCustomerLabel),
	});

	if (loading) {
		return <LoadingIcon size={SIZES.cmsContent} />;
	}

	// Handle target card change
	const onChangeCardSelection = (selection) => {
		if (formHelper.getFieldError('targetSubsystemAccountReference')) {
			formHelper.clearFieldErrors('targetSubsystemAccountReference');
		}

		const selectionData = JSON.parse(selection.value);

		setTargetCard(
			selectionData.targetSubsystemAccountReference,
			selection.label,
			selectionData.targetCustomerId,
		); // sets full target card object in context
	};

	// Create list of target card options
	const cardOptions = map(travelCards, targetCard => {
		const { wsSubsystemAccountInfo, managedCustomerLinkInfo } = targetCard;
		const { subsystemAccountDetailedInfo } = wsSubsystemAccountInfo;
		const token = head(subsystemAccountDetailedInfo.tokens);
		const nickname = getTokenNicknameAndPan(token);

		const managedCustomerLabel = managedCustomerLinkInfo ?
			find(managedCustomerLabels, { customId: managedCustomerLinkInfo.linkId })
			: null;

		const managedCustomerPostfix = managedCustomerLabel
			? ' ' + getContentOrFallback(managedCustomerLabel, `Owner: ${managedCustomerLinkInfo.firstName}`)
			: '';

		return {
			label: `${nickname}${managedCustomerPostfix}`,
			value: JSON.stringify(
				{
					targetSubsystemAccountReference: wsSubsystemAccountInfo.subsystemAccountReference,
					targetCustomerId: managedCustomerLinkInfo?.managedCustomerId,
				},
			),
		};
	});

	return (
		<CmsContentList list={values(cms)}>{() => (
			<Select
				name="targetSubsystemAccountReference"
				label={<CmsContentRenderer.Span className={style.transferSubheader}
					contentKey={cms.transferToLabel}
					fallbackValue="Select which Charlie Card to transfer to" />}
				options={cardOptions}
				error={isEmpty(cardOptions)
					? <CmsContentRenderer.Span contentKey={cms.transferNoOptions}
						fallbackValue={'No transfer options'} />
					: formHelper.getFieldError('targetSubsystemAccountReference')}
				onChange={onChangeCardSelection}
				value={cart.targetCard
					? {
						label: cart.targetCard.nickname,
						value: JSON.stringify({
							targetSubsystemAccountReference: cart.targetCard.subsystemAccountReference,
							targetCustomerId: cart.targetCustomerId,
						}),
					}
					: null}
				searchable={false}
			/>
		)}</CmsContentList>
	);
};

export default TransferTargetCardSelector;
