import {
	clone,
	get,
} from 'lodash';
import strToBoolean from "utils/StrToBoolean.mjs";
import { PHASES } from "utils/constants/Phases.mjs";
import {
	B2B,
	DISCOUNT_PORTAL,
	GROUP_ADMIN,
} from "utils/constants/Sites.mjs";

export const SSO_PROVIDER_GOOGLE = "GOOGLE";
export const SSO_PROVIDER_FACEBOOK = "FACEBOOK";
export const SSO_PROVIDER_APPLE = "APPLE";

const isProduction = process.env.NODE_ENV === 'production';

// process.env.SITE is hardcoded by DefinePlugin
const SITE = process.env.SITE;
const PHASE = process.env.PHASE;

export const defaults = {
	LANGUAGE: 'en',
	ACCEPTED_PAYMENT_TYPES: JSON.stringify([ "Visa", "MasterCard", "American Express", "Discover" ]),
	// Value in seconds, defaults to 3 days;
	PASS_PRODUCT_EXPIRES_WARNING: '259200',

	SAVE_LANGUAGE_TO_BO: true,

	TRANSIT_ACCOUNT_MAX_BALANCE: "50000", // https://reflexions.slack.com/archives/CA82SPCTV/p1629465090100200

	SITE,
	PHASE,
	isB2BApi: [ GROUP_ADMIN, B2B, DISCOUNT_PORTAL ].includes(SITE),
	isGroupAdmin: SITE === GROUP_ADMIN,
	isB2B: [ GROUP_ADMIN, B2B ].includes(SITE),
	isDiscount: [ GROUP_ADMIN, DISCOUNT_PORTAL ].includes(SITE),

	// Need cubic to confirm the min ammount
	// https://reflexions.slack.com/archives/CCF68M49M/p1654102723032389?thread_ts=1654102456.870229&cid=CCF68M49M
	TRANSFER_MIN_AMOUNT: 500,

	CONTACT_US_OPTIONS: JSON.stringify({
		FeedbackOnWebsite: "Website feedback",
		GeneralFeedbackAndInquiries: "General feedback or inquiry",
		CardFailure: "Issue with my card",
		PaymentFailure: "Issue with a payment",
		AccountUpdate: "Account update or termination request",
		ErrorReport: "Report of website error",
	}),

	FORCE_SERVER_RENDER: false,
	TESTING_ROUTES: !isProduction,

	APOLLO_IMMUTABLE: true,
	APOLLO_CLIENT_CREDENTIALS: 'same-origin',

	GOOGLE_ANALYTICS_TRACKERS: '{}',
	GOOGLE_ANALYTICS_IMMEDIATE_PAGEVIEW: false,

	SESSION_CHECK_INTERVAL: '60',
	SESSION_FRONTEND_WARNING: '600',
	SESSION_BACKEND_TIMEOUT: '3600',

	MIN_SECURITY_QUESTION_ANSWER_LENGTH: '6',
	MAX_SECURITY_QUESTION_ANSWER_LENGTH: '64',

	FORMHELPER_DEBUG_WIRED: !isProduction,
	LOG_FUNDING_SOURCES_CONTEXT_CHANGES: !isProduction,
	LOG_PRODUCT_CAT_DISPLAY_GROUP: !isProduction,

	EULA_CHANNELS: JSON.stringify([ 'Any', 'TravelerWeb' ]),
	EULA_CHANNELS_AUTOLOAD: JSON.stringify([ 'Any' ]),
	EULA_TOS: "NewTravelerRegisteredAccount",
	EULA_ACH_TYPE: "Traveler_NewACHFundingSource_GeneralTerms",
	EULA_AUTOLOAD: JSON.stringify({ eulaType: "Traveler_NewTravelerAutoload_GeneralTerms" }),

	CONTENT_FALLBACK_OVERRIDE: isProduction ? null : '[MISSING {key}] {fallbackValue}',
	CONTENT_FALLBACK_COLORED: !isProduction,

	AUTOMATIC_RELOAD_BALANCE_THRESHOLD: JSON.stringify([ 10000, 5000, 2000, 1000 ]),

	MAX_TRAVEL_CARD_LIMIT: '10',

	// as of 09/30/2021 officially 7 day does not confirm,
	// let's use OMNY time period for now as per Greg G
	UNREGISTERED_HISTORY_DAYS: '7',

	// Registered Users will be able to correct missing or auto-filled taps within
	// an MBTA specified time period (e.g. 20 days). Since location data is
	// required for pricing fares, the time period for corrections should be set
	// to be less than the cutoff for location data.
	// documentation see in the ticket https://reflexions.atlassian.net/browse/MBTA-1313
	ALERTS_MAX_DAYS: '20',

	// Enables the Connected Services page and link
	ENABLE_CONNECTED_SERVICES: false,

	// Enables the Group membership panels on Card Overview and Settings
	ENABLE_TOKEN_GROUPS: false,

	ENABLE_INTERNATIONAL_ADDRESS: PHASE >= PHASES.pilot2,
	ENABLE_NEW_CARD_PURCHASE: PHASE >= PHASES.pilot2,
	ENABLE_MODE_FILTER: PHASE >= PHASES.pilot2,
	ENABLE_EMV_RELOAD: PHASE >= PHASES.pilot2,
	ENABLE_EMV_AUTOLOAD: PHASE >= PHASES.pilot2,
	ENABLE_UPGRADE_CARD: PHASE >= PHASES.pilot2,
	ENABLE_MOBILE_FARE_CARD: PHASE >= PHASES.pilot2,
	ENABLE_DOWNLOAD_INFORMATION: PHASE >= PHASES.pilot2,

	// Per Alessandro we are to use 726 code for debt resolution
	// https://reflexions.slack.com/archives/GA82SPCTV/p1626452465351900?thread_ts=1626451893.350200&cid=GA82SPCTV
	RESOLVE_BALANCE_REASON_CODE: '726',


	// https://reflexions.slack.com/archives/CCF68M49M/p1687366399087029?thread_ts=1686680117.370709&cid=CCF68M49M
	// Cubic Key = AIzaSyCseqt-SO_7aK4alxV3Yla5N5oC3M909mQ

	// https://reflexions.atlassian.net/browse/MBTA-998?focusedCommentId=26925
	// MBTA Key = AIzaSyALOA56mCVzuUkxfJoe1s9gXB2cVMEofig
	GOOGLE_MAPS_API_KEY: 'AIzaSyCseqt-SO_7aK4alxV3Yla5N5oC3M909mQ',

	// see also LEAFLET_TILE_HOST for content security policy
	// mbta.com uses "https://cdn.mbta.com/osm_tiles/{z}/{x}/{y}.png"
	LEAFLET_TILE_URL: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',

	// https://reflexions.slack.com/archives/CBSGTBDA7/p1637079641224100
	POS_DMS: false,

	PYPESTREAM_CHATBOT_APP_ID: '',
	PYPESTREAM_CHATBOT_DOMAIN: 'prod',
	PYPESTREAM_CHATBOT_ENV: 'prod',
	PYPESTREAM_CHATBOT_SCRIPT_SRC: 'https://web.pypestream.com/launcher.js?ts=',
	PYPESTREAM_CHATBOT_OPTIONS: JSON.stringify({}),
	PYPESTREAM_CHATBOT_ELEMENT_ID: 'pypestream-wrapper',
	PYPESTREAM_CHATBOT_WRAPPED_ELEMENT_ID: '#pypestream-iframe',
	PYPESTREAM_CHATBOT_LANGUAGE_OVERRIDES: JSON.stringify({
		"ar-001": "ar",
		"fr-HT": "ht",
		'cmn': 'zh',
		'yue': 'zh',
	}),

	MBTA_HEADER: true,

	AUTO_DISMISS_TOAST: true,

	MBTA_CHARLIE_PASS_URL: 'https://www.mbta.com/charlie/pass#',
	APPLE_OAUTH_ENABLED: true,
	GOOGLE_OAUTH_ENABLED: true,
	FACEBOOK_OAUTH_ENABLED: true,

	MANAGE_TOKENS_REASON_CODES: JSON.stringify({
		"Block": '742',
		"Unblock": '719',
		"Archive": '761',
		"Activate": '25',
	}),

	// 752 and 733 came from https://www.dropbox.com/s/qsiqgyzrbyxrrab/AM-019%20Replace%20a%20Lost%20Charlie%20Card%20%281%29.docx?dl=0
	// 749 and 742 came from CRM lost/stolen card data https://reflexions.atlassian.net/browse/MBTA-3109
	// and crossreferrenced with customerservice/reasoncodes data matching by reasonCodeId
	INACTIONABLE_TOKEN_REASON_CODES: JSON.stringify([
		{
			"reasonCodeId": 733,
			"reasonCodeTypeId": 16,
			"description": "Stolen",
		},
		{
			"reasonCodeId": 749,
			"reasonCodeTypeId": 13,
			"description": "Stolen",
		},
		{
			"reasonCodeId": 752,
			"reasonCodeTypeId": 15,
			"description": "Lost",
		},
		{
			"reasonCodeId": 742,
			"reasonCodeTypeId": 13,
			"description": "Lost",
		},
	]),

	// Per Raj only statusReasonCode -4200 should be elidgibable to make token primary
	// https://reflexions.slack.com/archives/CCF68M49M/p1719580227327689
	ACTIONABLE_TOKEN_RESONCODE_IDS: JSON.stringify([
		{
			"reasonCodeId": -4200,
			"description": "Unblocked travel token to be the primary",
		},
	]),


	//https://reflexions.slack.com/archives/CCF68M49M/p1718174814856899?thread_ts=1718079243.443039&cid=CCF68M49M
	MOBILE_FARE_CARD_TOKEN_MEDIA_TYPES: JSON.stringify([
		"StandardBankcard",
		"OpenTransitVirtual",
		"VirtualToken",
	]),

	MISSED_TAP_MAX_LOCATIONS: '100',

	CARD_NICKNAME_MAXLENGTH: '20',

	DEFAULT_FILTER_DAYS: '45',

	CONTACT_US_MAX_CHAR_LENGTH: '2000',
	DISPUTE_RIDE_MAX_CHAR_LENGTH: '1000',
	DISPUTE_PURCHASE_MAX_CHAR_LENGTH: '256',

	MBTA_MAP_RECENTER_ON_CHANGE_THRESHOLD: '0.01',

	TERMINATE_TOKEN_AFTER_REPLACEMENT: true,

	DEBUG_STRIPPED: false,

	COMING_SOON_LINK_CHARLIE_CARD: PHASE < PHASES.pilot2,
	COMING_SOON_PURCHASE_CHARLIE_CARD: PHASE < PHASES.pilot2,
	COMING_SOON_RETAIL_LOCATION_FINDER: PHASE < PHASES.pilot2,
	COMING_SOON_PAYMENT_METHODS: PHASE < PHASES.pilot2,
	COMING_SOON_MOBILE_CARD: PHASE < PHASES.pilot2,

	// https://reflexions.atlassian.net/browse/MBTA-3105
	// set reduced fare values via env var
	REDUCED_FARE_RIDER_CLASSES: JSON.stringify({
		"senior": 2,
	}),
};

// Note that these are global across all requests
// eslint-disable-next-line complexity
const get_vars_from_env = (ProcessEnvVars) => {

	const SESSION_BACKEND_TIMEOUT = parseInt(get(ProcessEnvVars, 'SESSION_BACKEND_TIMEOUT', 3600), 10);
	const PUBLIC_URL = get(ProcessEnvVars, 'PUBLIC_URL', process.env.PUBLIC_PATH);

	const EULA_TOS = ProcessEnvVars.EULA_TOS ?? defaults.EULA_TOS;

	// we don't return all the server env vars
	return {
		...defaults,

		// PUBLIC_URL is set to '' in test. See razzle/scripts/test.js
		// process.env.PUBLIC_PATH is inlined by razzle during the build
		PUBLIC_URL: get(ProcessEnvVars, 'PUBLIC_URL', process.env.PUBLIC_PATH),

		// show the env in the tab title as `[env] Page Title`
		ENV_NAME: ProcessEnvVars.ENV_NAME ?? undefined,

		SITE,
		isB2BApi: defaults.isB2BApi,
		isGroupAdmin: defaults.isGroupAdmin,

		// Note: we don't include NODE_ENV here, because process.env.NODE_ENV
		// and some other Razzle vars are inlined during the build
		// https://github.com/jaredpalmer/razzle#build-time-variables

		FORCE_SERVER_RENDER: strToBoolean(ProcessEnvVars.FORCE_SERVER_RENDER),

		// returns what you'd need to prefix to a url path to get to the frontend
		// so if we're running in the frontend already, it'd be ""
		// if running under storybook, it might be http://localhost:3000
		FRONTEND_CLIENT_BASE: '',

		CONTACT_US_OPTIONS: JSON.parse(ProcessEnvVars.CONTACT_US_OPTIONS ?? defaults.CONTACT_US_OPTIONS),

		GOOGLE_ANALYTICS_TRACKERS: JSON.parse(get(ProcessEnvVars, 'GOOGLE_ANALYTICS_TRACKERS', "{}")),
		GOOGLE_ANALYTICS_IMMEDIATE_PAGEVIEW: strToBoolean(ProcessEnvVars.GOOGLE_ANALYTICS_IMMEDIATE_PAGEVIEW),

		APOLLO_IMMUTABLE: strToBoolean(ProcessEnvVars.APOLLO_IMMUTABLE ?? defaults.APOLLO_IMMUTABLE),
		APOLLO_CLIENT_CREDENTIALS: defaults.APOLLO_CLIENT_CREDENTIALS,

		OAUTH_REDIRECT_PATH: ProcessEnvVars.OAUTH_REDIRECT_PATH,

		SSO_BO_IDS: JSON.parse(ProcessEnvVars.SSO_BO_IDS ?? JSON.stringify({
			// these _AT versions are what the mobile app uses
			// the first one is what we use when talking to the BO
			[ SSO_PROVIDER_FACEBOOK ]: [ SSO_PROVIDER_FACEBOOK, "FACEBOOK_AT" ],
			[ SSO_PROVIDER_GOOGLE ]: [ SSO_PROVIDER_GOOGLE ],
			[ SSO_PROVIDER_APPLE ]: [ SSO_PROVIDER_APPLE, "APPLE_MOBILE" ],
		})),
		FACEBOOK_OAUTH_APP_ID: ProcessEnvVars.FACEBOOK_OAUTH_APP_ID ?? null,
		GOOGLE_OAUTH_CLIENT_ID: ProcessEnvVars.GOOGLE_OAUTH_CLIENT_ID ?? null,
		APPLE_OAUTH_CLIENT_ID: ProcessEnvVars.APPLE_OAUTH_CLIENT_ID ?? null,

		APPLE_OAUTH_ENABLED: strToBoolean(ProcessEnvVars.APPLE_OAUTH_ENABLED ?? defaults.APPLE_OAUTH_ENABLED),
		GOOGLE_OAUTH_ENABLED: strToBoolean(ProcessEnvVars.GOOGLE_OAUTH_ENABLED ?? defaults.GOOGLE_OAUTH_ENABLED),
		FACEBOOK_OAUTH_ENABLED: strToBoolean(ProcessEnvVars.FACEBOOK_OAUTH_ENABLED ?? defaults.FACEBOOK_OAUTH_ENABLED),

		RECAPTCHA_SITEKEY: ProcessEnvVars.RECAPTCHA_SITEKEY,
		ERRORS_TO_SCREEN: strToBoolean(ProcessEnvVars.ERRORS_TO_SCREEN),
		IS_HTTPS: Boolean(PUBLIC_URL.match(/^https:\/\//i)),

		// values are seconds
		SESSION_CHECK_INTERVAL: parseInt(get(ProcessEnvVars, 'SESSION_CHECK_INTERVAL', 60), 10),
		SESSION_BACKEND_TIMEOUT,
		SESSION_FRONTEND_TIMEOUT: parseInt(get(ProcessEnvVars, 'SESSION_FRONTEND_TIMEOUT', SESSION_BACKEND_TIMEOUT), 10),
		// sec before timeout that warning dialog is open
		SESSION_FRONTEND_WARNING: parseInt(get(ProcessEnvVars, 'SESSION_FRONTEND_WARNING', defaults.SESSION_FRONTEND_WARNING), 10),

		CONTENT_FALLBACK_OVERRIDE: ProcessEnvVars.CONTENT_FALLBACK_OVERRIDE ?? defaults.CONTENT_FALLBACK_OVERRIDE,
		CONTENT_FALLBACK_COLORED: strToBoolean(ProcessEnvVars.CONTENT_FALLBACK_COLORED ?? defaults.CONTENT_FALLBACK_COLORED),

		EULA_CHANNELS: JSON.parse(ProcessEnvVars.EULA_CHANNELS ?? defaults.EULA_CHANNELS),
		EULA_CHANNELS_AUTOLOAD: JSON.parse(ProcessEnvVars.EULA_CHANNELS_AUTOLOAD ?? defaults.EULA_CHANNELS_AUTOLOAD),
		EULA_TOS,
		EULA_REQUIRED_FEATURES: JSON.parse(ProcessEnvVars.EULA_REQUIRED_FEATURES ?? JSON.stringify([ EULA_TOS ])),
		EULA_AUTOLOAD: JSON.parse(ProcessEnvVars.EULA_AUTOLOAD ?? defaults.EULA_AUTOLOAD),

		//https://reflexions.atlassian.net/browse/MBTA-2493
		// International Addresses deferred to Pilot 2
		ENABLE_INTERNATIONAL_ADDRESS: strToBoolean(ProcessEnvVars.ENABLE_INTERNATIONAL_ADDRESS ?? defaults.ENABLE_INTERNATIONAL_ADDRESS),

		// https://reflexions.atlassian.net/browse/MBTA-2422
		// For Pilot 1 it has been requested to disable the new card purchases
		ENABLE_NEW_CARD_PURCHASE: strToBoolean(ProcessEnvVars.ENABLE_NEW_CARD_PURCHASE ?? defaults.ENABLE_NEW_CARD_PURCHASE),

		// https://reflexions.atlassian.net/browse/MBTA-2434
		// Deferred til Pilot 2
		ENABLE_MODE_FILTER: strToBoolean(ProcessEnvVars.ENABLE_MODE_FILTER ?? defaults.ENABLE_MODE_FILTER),

		// If the selected Credential is an EMV card, the EMV card will be the only payment method allowed for purchases.
		// No other payment cards may be used for purchases of products for the Transit Account.
		ENABLE_EMV_RELOAD: strToBoolean(ProcessEnvVars.ENABLE_EMV_RELOAD ?? defaults.ENABLE_EMV_RELOAD),

		// https://reflexions.atlassian.net/browse/MBTA-2556
		// EMV autoload is currently broken from the BO. Waiting on resolution.
		// Deferred til Pilot 2
		ENABLE_EMV_AUTOLOAD: strToBoolean(ProcessEnvVars.ENABLE_EMV_AUTOLOAD ?? defaults.ENABLE_EMV_RELOAD),

		// https://reflexions.atlassian.net/browse/MBTA-2574
		// Upgrade card is currently broken from the BO. Waiting on resolution.
		// Deferred til Pilot 2
		ENABLE_UPGRADE_CARD: strToBoolean(ProcessEnvVars.ENABLE_UPGRADE_CARD ?? defaults.ENABLE_UPGRADE_CARD),

		// https://reflexions.atlassian.net/browse/MBTA-2704?
		// Ability to link/delink virtual cards & mobile fare cards
		// Deferred til Pilot 2
		ENABLE_MOBILE_FARE_CARD: strToBoolean(ProcessEnvVars.ENABLE_UPGRADE_CARD ?? defaults.ENABLE_MOBILE_FARE_CARD),

		ENABLE_DOWNLOAD_INFORMATION: strToBoolean(ProcessEnvVars.ENABLE_DOWNLOAD_INFORMATION ?? defaults.ENABLE_DOWNLOAD_INFORMATION),

		// https://reflexions.atlassian.net/browse/MBTA-2775
		// Security Question length
		MIN_SECURITY_QUESTION_ANSWER_LENGTH: parseInt(ProcessEnvVars.MIN_SECURITY_QUESTION_ANSWER_LENGTH ?? defaults.MIN_SECURITY_QUESTION_ANSWER_LENGTH, 10),
		MAX_SECURITY_QUESTION_ANSWER_LENGTH: parseInt(ProcessEnvVars.MAX_SECURITY_QUESTION_ANSWER_LENGTH ?? defaults.MAX_SECURITY_QUESTION_ANSWER_LENGTH, 10),

		REGISTRATION_POSTAL_CODE: strToBoolean(ProcessEnvVars.REGISTRATION_POSTAL_CODE ?? true),

		ACCEPTED_PAYMENT_TYPES: JSON.parse(ProcessEnvVars.ACCEPTED_PAYMENT_TYPES ?? defaults.ACCEPTED_PAYMENT_TYPES),
		PASS_PRODUCT_EXPIRES_WARNING: parseInt(ProcessEnvVars.PASS_PRODUCT_EXPIRES_WARNING ?? defaults.PASS_PRODUCT_EXPIRES_WARNING, 10),

		SAVE_LANGUAGE_TO_BO: ProcessEnvVars.SAVE_LANGUAGE_TO_BO ?? defaults.SAVE_LANGUAGE_TO_BO,

		TRANSIT_ACCOUNT_MAX_BALANCE: parseInt(ProcessEnvVars.TRANSIT_ACCOUNT_MAX_BALANCE ?? defaults.TRANSIT_ACCOUNT_MAX_BALANCE, 10),

		TRANSFER_MIN_AMOUNT: parseInt(ProcessEnvVars.TRANSFER_MIN_AMOUNT ?? defaults.TRANSFER_MIN_AMOUNT, 10),

		DEFAULT_FILTER_DAYS: ProcessEnvVars.DEFAULT_FILTER_DAYS ?? defaults.DEFAULT_FILTER_DAYS,

		CARD_OVERVIEW_RECENT_TRIPS_LIMIT: parseInt(get(ProcessEnvVars, 'CARD_OVERVIEW_RECENT_TRIPS_LIMIT', "5"), 10),

		// the default here should match EnvVars.CHARGES_HISTORY_PAGE_SIZE
		CHARGES_HISTORY_PAGE_SIZE: parseInt(ProcessEnvVars.CHARGES_HISTORY_PAGE_SIZE ?? "15", 10),
		MAX_CHARGES_HISTORY_PAGE_SIZE: parseInt(get(ProcessEnvVars, 'MAX_CHARGES_HISTORY_PAGE_SIZE', "2000"), 10),

		// the default here should match EnvVars.TRIPS_HISTORY_PAGE_SIZE
		// set up 15 rows per page as per design
		// slack full conversation https://reflexions.slack.com/archives/CDTQPPXJ8/p1659468468116519
		TRIPS_HISTORY_PAGE_SIZE: parseInt(get(ProcessEnvVars, 'TRIPS_HISTORY_PAGE_SIZE', "15"), 10),
		MAX_TRIPS_HISTORY_PAGE_SIZE: parseInt(get(ProcessEnvVars, 'MAX_TRIPS_HISTORY_PAGE_SIZE', "2000"), 10),

		MAX_TRAVEL_CARD_LIMIT: parseInt(ProcessEnvVars.MAX_TRAVEL_CARD_LIMIT ?? defaults.MAX_TRAVEL_CARD_LIMIT, 10),

		// the default here should match EnvVars.POINT_OF_SALE_LOCATIONS_PAGE_SIZE
		POINT_OF_SALE_LOCATIONS_PAGE_SIZE: parseInt(ProcessEnvVars.POINT_OF_SALE_LOCATIONS_PAGE_SIZE ?? "10", 10),


		AUTOMATIC_RELOAD_BALANCE_THRESHOLD: JSON.parse(ProcessEnvVars.AUTOMATIC_RELOAD_BALANCE_THRESHOLD ?? defaults.AUTOMATIC_RELOAD_BALANCE_THRESHOLD),

		// Enabled various pages / sections
		ENABLE_CONNECTED_SERVICES: strToBoolean(ProcessEnvVars.ENABLE_CONNECTED_SERVICES ?? defaults.ENABLE_CONNECTED_SERVICES),
		ENABLE_TOKEN_GROUPS: strToBoolean(ProcessEnvVars.ENABLE_TOKEN_GROUPS ?? defaults.ENABLE_TOKEN_GROUPS),

		UNREGISTERED_HISTORY_DAYS: parseInt(ProcessEnvVars.UNREGISTERED_HISTORY_DAYS ?? defaults.UNREGISTERED_HISTORY_DAYS, 10),

		ALERTS_MAX_DAYS: parseInt(ProcessEnvVars.ALERTS_MAX_DAYS ?? defaults.ALERTS_MAX_DAYS, 10),

		CONTACT_US_MAX_CHAR_LENGTH: parseInt(ProcessEnvVars.CONTACT_US_MAX_CHAR_LENGTH ?? defaults.CONTACT_US_MAX_CHAR_LENGTH, 10),

		DISPUTE_RIDE_MAX_CHAR_LENGTH: parseInt(ProcessEnvVars.DISPUTE_RIDE_MAX_CHAR_LENGTH ?? defaults.DISPUTE_RIDE_MAX_CHAR_LENGTH, 10),

		DISPUTE_PURCHASE_MAX_CHAR_LENGTH: parseInt(ProcessEnvVars.DISPUTE_PURCHASE_MAX_CHAR_LENGTH ?? defaults.DISPUTE_PURCHASE_MAX_CHAR_LENGTH, 10),

		// defaults to true
		CVV_FIELD_ENABLED: strToBoolean(ProcessEnvVars.CVV_FIELD_ENABLED ?? true),

		LOG_FUNDING_SOURCES_CONTEXT_CHANGES: strToBoolean(ProcessEnvVars.LOG_FUNDING_SOURCES_CONTEXT_CHANGES ?? (defaults.LOG_FUNDING_SOURCES_CONTEXT_CHANGES ? "1" : "")),
		LOG_PRODUCT_CAT_DISPLAY_GROUP: strToBoolean(ProcessEnvVars.LOG_PRODUCT_CAT_DISPLAY_GROUP ?? (defaults.LOG_PRODUCT_CAT_DISPLAY_GROUP ? "1" : "")),

		RESOLVE_BALANCE_REASON_CODE: ProcessEnvVars.RESOLVE_BALANCE_REASON_CODE ?? defaults.RESOLVE_BALANCE_REASON_CODE,

		MBTA_HEADER: strToBoolean(ProcessEnvVars.MBTA_HEADER ?? defaults.MBTA_HEADER),

		AUTO_DISMISS_TOAST: strToBoolean(ProcessEnvVars.AUTO_DISMISS_TOAST ?? defaults.AUTO_DISMISS_TOAST),

		GOOGLE_MAPS_API_KEY: ProcessEnvVars.GOOGLE_MAPS_API_KEY ?? defaults.GOOGLE_MAPS_API_KEY,
		LEAFLET_TILE_URL: ProcessEnvVars.LEAFLET_TILE_URL ?? defaults.LEAFLET_TILE_URL,

		POS_DMS: strToBoolean(ProcessEnvVars.POS_DMS ?? defaults.POS_DMS),

		PYPESTREAM_CHATBOT_APP_ID: ProcessEnvVars.PYPESTREAM_CHATBOT_APP_ID ?? defaults.PYPESTREAM_CHATBOT_APP_ID,
		PYPESTREAM_CHATBOT_DOMAIN: ProcessEnvVars.PYPESTREAM_CHATBOT_DOMAIN ?? defaults.PYPESTREAM_CHATBOT_DOMAIN,
		PYPESTREAM_CHATBOT_ENV: ProcessEnvVars.PYPESTREAM_CHATBOT_ENV ?? defaults.PYPESTREAM_CHATBOT_ENV,
		PYPESTREAM_CHATBOT_SCRIPT_SRC: ProcessEnvVars.PYPESTREAM_CHATBOT_SCRIPT_SRC ?? defaults.PYPESTREAM_CHATBOT_SCRIPT_SRC,
		PYPESTREAM_CHATBOT_OPTIONS: JSON.parse(ProcessEnvVars.PYPESTREAM_CHATBOT_OPTIONS ?? defaults.PYPESTREAM_CHATBOT_OPTIONS),
		PYPESTREAM_CHATBOT_ELEMENT_ID: ProcessEnvVars.PYPESTREAM_CHATBOT_ELEMENT_ID ?? defaults.PYPESTREAM_CHATBOT_ELEMENT_ID,
		PYPESTREAM_CHATBOT_WRAPPED_ELEMENT_ID: ProcessEnvVars.PYPESTREAM_CHATBOT_WRAPPED_ELEMENT_ID ?? defaults.PYPESTREAM_CHATBOT_WRAPPED_ELEMENT_ID,
		PYPESTREAM_CHATBOT_LANGUAGE_OVERRIDES: JSON.parse(ProcessEnvVars.PYPESTREAM_CHATBOT_LANGUAGE_OVERRIDES ?? defaults.PYPESTREAM_CHATBOT_LANGUAGE_OVERRIDES),

		MBTA_CHARLIE_PASS_URL: ProcessEnvVars.MBTA_CHARLIE_PASS_URL ?? defaults.MBTA_CHARLIE_PASS_URL,

		MANAGE_TOKENS_REASON_CODES: JSON.parse(ProcessEnvVars.MANAGE_TOKENS_REASON_CODES ?? defaults.MANAGE_TOKENS_REASON_CODES),

		INACTIONABLE_TOKEN_REASON_CODES: JSON.parse(ProcessEnvVars.INACTIONABLE_TOKEN_REASON_CODES ?? defaults.INACTIONABLE_TOKEN_REASON_CODES),

		MOBILE_FARE_CARD_TOKEN_MEDIA_TYPES: JSON.parse(ProcessEnvVars.MOBILE_FARE_CARD_TOKEN_MEDIA_TYPES ?? defaults.MOBILE_FARE_CARD_TOKEN_MEDIA_TYPES),

		ACTIONABLE_TOKEN_RESONCODE_IDS: JSON.parse(ProcessEnvVars.ACTIONABLE_TOKEN_RESONCODE_IDS ?? defaults.ACTIONABLE_TOKEN_RESONCODE_IDS),

		MISSED_TAP_MAX_LOCATIONS: parseInt(ProcessEnvVars.MISSED_TAP_MAX_LOCATIONS ?? defaults.MISSED_TAP_MAX_LOCATIONS, 10),

		CARD_NICKNAME_MAXLENGTH: parseInt(ProcessEnvVars.CARD_NICKNAME_MAXLENGTH ?? defaults.CARD_NICKNAME_MAXLENGTH, 10),

		MBTA_MAP_RECENTER_ON_CHANGE_THRESHOLD: parseFloat(ProcessEnvVars.MBTA_MAP_RECENTER_ON_CHANGE_THRESHOLD ?? defaults.MBTA_MAP_RECENTER_ON_CHANGE_THRESHOLD),

		TERMINATE_TOKEN_AFTER_REPLACEMENT: strToBoolean(ProcessEnvVars.TERMINATE_TOKEN_AFTER_REPLACEMENT ?? defaults.TERMINATE_TOKEN_AFTER_REPLACEMENT),

		DEBUG_STRIPPED: strToBoolean(ProcessEnvVars.DEBUG_STRIPPED ?? defaults.DEBUG_STRIPPED),

		COMING_SOON_LINK_CHARLIE_CARD: strToBoolean(ProcessEnvVars.COMING_SOON_LINK_CHARLIE_CARD ?? defaults.COMING_SOON_LINK_CHARLIE_CARD),
		COMING_SOON_PURCHASE_CHARLIE_CARD: strToBoolean(ProcessEnvVars.COMING_SOON_PURCHASE_CHARLIE_CARD ?? defaults.COMING_SOON_PURCHASE_CHARLIE_CARD),
		COMING_SOON_RETAIL_LOCATION_FINDER: strToBoolean(ProcessEnvVars.COMING_SOON_RETAIL_LOCATION_FINDER ?? defaults.COMING_SOON_RETAIL_LOCATION_FINDER),
		COMING_SOON_PAYMENT_METHODS: strToBoolean(ProcessEnvVars.COMING_SOON_PAYMENT_METHODS ?? defaults.COMING_SOON_PAYMENT_METHODS),
		COMING_SOON_MOBILE_CARD: strToBoolean(ProcessEnvVars.COMING_SOON_MOBILE_CARD ?? defaults.COMING_SOON_MOBILE_CARD),

		REDUCED_FARE_RIDER_CLASSES: JSON.parse(ProcessEnvVars.REDUCED_FARE_RIDER_CLASSES ?? defaults.REDUCED_FARE_RIDER_CLASSES),
	};
};

const get_vars_from_html = () => {
	const appVarsElement = document.getElementById('app-vars');

	if (!appVarsElement) {
		console.error("Unable to find #app-vars element");
		return {
			...defaults,
			// TODO: remove the part after the path
			PUBLIC_URL: window.location,
		};
	}

	return {
		...defaults,
		...JSON.parse(appVarsElement.text),
	};
};

const get_vars_client_side = () => {
	if (process.env.SITE === 'storybook') {
		return process.env; // storybook's main.js sets this
	}
	else {
		return get_vars_from_html();
	}
};

const PublicAppVars = (typeof window === 'undefined' || process.env.NODE_ENV === 'test')
	? get_vars_from_env(clone(process.env))
	: get_vars_client_side();

export const mockSite = (site) => {
	PublicAppVars.SITE = site;
	PublicAppVars.isB2B = site === B2B;
	PublicAppVars.isDiscount = site === DISCOUNT_PORTAL;
	PublicAppVars.isGroupAdmin = site === GROUP_ADMIN;
};

export default PublicAppVars;
