import React, { useState } from 'react';
import { values } from 'lodash';
import { useLocation } from "react-router-dom";
import {
	FullName,
	GET_FULLNAME,
} from "components/data/session-user/SessionUser.js";
import { useCustomerTravelCards } from 'components/data/session-user/useCustomerTravelCards.js';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import HelmetOGTags from 'layouts/HelmetOGTags.js';
import CardList from 'components/account/CardList.js';
import Container from 'components/Container.js';
import ContactCustomerService from 'components/banners/ContactCustomerService.js';
import InfoPanel from 'components/InfoPanel.js';
import AddAnotherCardActions from 'components/account/AddAnotherCardActions.js';
import useStdQuery from "components/data/hooks/useStdQuery.js";
import LoadingIcon, { SIZES } from "components/icons/LoadingIcon.js";
import { maskedError } from "components/data/StdQuery.js";
import SetupSecurityQuestionsBanner from 'components/banners/SetupSecurityQuestions.js';
import PurchaseCharlieCardBanner from 'components/account/purchases/PurchaseCharlieCardBanner.js';
import { parse as qs_parse } from 'qs';
import { hasMaxTravelCards } from 'components/account/hasMaxTravelCards.js';

import * as infoPanel from 'components/InfoPanel.module.css';
import * as style from './CardSelection.module.css';
import {  useBreadcrumbCallback } from 'context/BreadcrumbProvider';
import {  getHomeBreadcrumbs } from 'pages/Home';
import { BreadcrumbItem } from 'components/breadcrumb/Breadcrumb';
import routeKeys from 'CustomerRouteKeys';


const cms = {
	header: 'miscText.dashboard-header',
	subHeader: 'miscText.dashboard-subheader',
	maxLimit: 'miscHtml.dashboard-addcard-maxlimit',
	infoAlt: 'miscHtml.dashboard-info-alternates',

};

export const getCharlieCardBreadcrumbs = () =>  [
	...getHomeBreadcrumbs(),
	<BreadcrumbItem
		routeKey={routeKeys.AccountCardSelection}
		key={"fullName"}
	>
		<FullName />
	</BreadcrumbItem>,

];

const MyCharlieCards = () => {
	const location = useLocation();
	const [ consumedQueryParams, setConsumedQueryParams ] = useState(false);
	useBreadcrumbCallback(getCharlieCardBreadcrumbs);

	const query = qs_parse(location.search, {
		ignoreQueryPrefix: true,
	});

	const { data: nameData, loading: nameLoading, error: nameError } = useStdQuery(GET_FULLNAME);
	const {
		data: customerTravelCardsData,
		loading: customerTravelCardsLoading,
		error: customerTravelCardsError,
	} = useCustomerTravelCards();

	const firstname = nameData?.session.customer.contact.name.firstName;
 	const wsSubsystemAccountInfos = customerTravelCardsData?.subsystemAccountInfoQ ?? [];


	return (
		<>
			{query?.purchaseCard && !consumedQueryParams
				? <PurchaseCharlieCardBanner handleBannerClose={() => setConsumedQueryParams(true)} />
				: <SetupSecurityQuestionsBanner />}
			<CmsContentList
				list={values(cms)}
				sharedVariables={{ firstname }}
			>{() => (
					<Container>
						<HelmetOGTags title="Card Selection" />

						<div className={style.container}>
							<div className={style.header}>
								{nameLoading || nameError
									? "Welcome"
									: <CmsContentRenderer.H1
										className={style.pageTitle}
										contentKey={cms.header}
									/>
								}

								{wsSubsystemAccountInfos?.length > 0
									? <CmsContentRenderer.P
										contentKey={cms.subHeader}
										className={style.pageSubhead}
										fallbackValue="Select a card to view information."
									/>
									: null}
							</div>

							{customerTravelCardsLoading
								? <LoadingIcon size={SIZES.component} />
								: customerTravelCardsError
									? maskedError
									: <CardList {...{ wsSubsystemAccountInfos }} />}

							{!hasMaxTravelCards(wsSubsystemAccountInfos.length)
								? <AddAnotherCardActions />
								: (
									<InfoPanel>
										<CmsContentRenderer.Div
											className={infoPanel.content}
											contentKey={cms.maxLimit}
											fallbackValue="<p>You've reached the maximum number of cards allowed on this account. To remove a card from your account, select the card then navigate to the Card Settings page.</p>"
										/>
									</InfoPanel>
								)}

							<InfoPanel>
								<CmsContentRenderer.Div
									className={infoPanel.content}
									contentKey={cms.infoAlt}
									fallbackValue="<p>To view cards for other accounts that you manage, <a href='#accountmenu'>use the Account Selection menu</a> in the upper right hand corner of the screen.</p>"
								/>
							</InfoPanel>
						</div>
					</Container>
				)}</CmsContentList>

			<ContactCustomerService />
		</>
	);
};

export default MyCharlieCards;
