import PropTypes from "prop-types";
import cx from "classnames";
import React, {
	useState,
	useRef,
} from "react";
import {
	object as yup_object,
	ref as yup_ref,
	string as yup_string,
} from "yup";
import { values } from "lodash";

import { Lifecycles } from "libreact/lib/Lifecycles";
import useRecaptcha, { recaptchaYupValidations } from "components/Recaptcha.js";
import Button from "components/Button.js";
import Input from "components/forms/Input";
import GoogleAnalytics from "utils/analytics/GoogleAnalytics.js";
import { useSessionCheckContext } from "components/data/session-user/SessionCheck";
import PasswordValidation from "components/auth/PasswordValidation";
import { GET_EMAIL } from "components/data/session-user/SessionUser";
import { passwordVerification } from "components/auth/LoginCredentials.js";
import CmsContentList from "components/data/CmsContentList.js";
import Modal from "components/Modal";
import CmsContentRenderer from "components/data/CmsContentRenderer.js";
import PreventDefault from 'utils/PreventDefault.js';
import { useModalContext } from 'context/ModalProvider.js';
import useStdQuery from "components/data/hooks/useStdQuery.js";
import useFormHelper from "utils/form-helper/useFormHelper.js";
import { BO_ERRORS, COLLECTIONS, getErrorKey, getMiscTextErrorKey } from "utils/GetErrorKey";
import {
	levels,
	noticeError,
} from "utils/Logger.js";

import * as editPasswordStyle from "components/modals/EditPassword.module.css";
import * as modalStyles from "components/Modal.module.css";



const cms = {
	pwHeader: "miscText.security-login-force-password-header",
	pwInfo: "miscText.security-login-force-password-info",
	pwEmailInfo: "miscText.security-login-force-password-email",
	pwNewPassLabel: 'miscText["security-login-force-password-newpassword.label"]',
	pwConfirmPassLabel: 'miscText["security-login-force-password-confirmpassword.label"]',
	pwCancel: "miscText.security-login-force-password-cancel",
	pwSubmit: "miscText.security-login-force-password-submit",
};

const getYupSchema = () => {
	const validations = {
		newPassword: passwordVerification().password,
		confirm: yup_string()
			.oneOf(
				[ yup_ref('newPassword') ],
				getMiscTextErrorKey('security-info-password-reset-error-mismatch'),
			)
			.required(getErrorKey(COLLECTIONS.securityProfile, 'password', BO_ERRORS.general.required)),
		...recaptchaYupValidations,
	};
	return yup_object().shape(validations);
};

const SocialResetPassword = ({ onSubmitDelink }) => {
	const [ submitting, setSubmitting ] = useState(false);
	const [ password, setPassword ] = useState('');

	const { syntheticTimerEvent } = useSessionCheckContext();
	const { setModal } = useModalContext();
	const email = useStdQuery(GET_EMAIL)?.data?.session?.customer?.contact?.email ?? '';
	const {
		formRef,
		formHelper,
	} = useFormHelper({ getYupSchema });

	const { Recaptcha, checkRecaptcha, resetRecaptcha } = useRecaptcha({ formHelper });

	const kickoffSubmit = async () => {
		await checkRecaptcha();

		setSubmitting(true);

		let validated;
		try {
			validated = await formHelper.startValidation(true);
		}
		catch (errorReport) {
			noticeError(null, levels.info, errorReport, `validation failed`);
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
			return;
		}

		GoogleAnalytics.logEvent("user setting password on passwordless sso account");

		try {
			await onSubmitDelink(validated.newPassword);
		}
		catch (errorReport) {
			resetRecaptcha();
			noticeError(null, levels.error, errorReport, `delink failed`);
			// we're not redirecting anywhere. Prepare the form for the next submit.
			formHelper.validationErrorHandler(errorReport);
			setSubmitting(false);
		}
		finally {
			// updates recaptchaEnabled state
			// not worried about this firing twice because it has a locking solution
			await syntheticTimerEvent();
		}
	};

	const onPasswordChange = (e) => setPassword(e.target.value);
	const onModalClose = () => setModal(null);

	return (
		<CmsContentList list={values(cms)} sharedVariables={{ email }}>{() => (
			<Modal
				title={<CmsContentRenderer.Span
					contentKey={cms.pwHeader}
					fallbackValue="Create a Password"
				/>}
				onModalClose={onModalClose}
				data-qa="SocialResetPasswordModal"
			>
				<Lifecycles didMount={formHelper.wireInputs}>
					<form
						method="post"
						data-qa="SocialResetPasswordForm"
						ref={formRef}
						onSubmit={PreventDefault(kickoffSubmit)}
					>
						<CmsContentRenderer.P
							contentKey={cms.pwInfo}
							fallbackValue="In order to disconnect all social accounts, you will need to setup a password."
						/>
						<CmsContentRenderer.P
							contentKey={cms.pwEmailInfo}
							fallbackValue="You will login with your email: {{email}}"
						/>
						{formHelper.getFieldErrorJsx('')}
						<div className={editPasswordStyle.passwordsAndValidationWrapper}>
							<div className={editPasswordStyle.passwordInputsWrapper}>
								<div className={modalStyles.row}>
									<Input
										label={<CmsContentRenderer.Span
											contentKey={cms.pwNewPassLabel}
											fallbackValue="New Password"
										/>}
										name="newPassword"
										type="password"
										error={formHelper.getFieldError('newPassword')}
										autoComplete="new-password"
										data-qa="SocialResetPasswordPswdInput"
										overrideClass={cx(modalStyles.password, editPasswordStyle.passwordInput)}
										onChange={onPasswordChange}
									/>
								</div>
								<div className={modalStyles.row}>
									<Input
										label={<CmsContentRenderer.Span
											contentKey={cms.pwConfirmPassLabel}
											fallbackValue="Confirm password"
										/>}
										name="confirm"
										type="password"
										error={formHelper.getFieldError('confirm')}
										autoComplete="new-password"
										data-qa="SocialResetPasswordReenterPswdInput"
										overrideClass={cx(modalStyles.password, editPasswordStyle.passwordInput)}
									/>
								</div>
							</div>
							<PasswordValidation
								password={password}
								overrideClass={editPasswordStyle.validationWrapper}
							/>
						</div>
						<Recaptcha />
						<div className={cx(modalStyles.actions, editPasswordStyle.modalActionsWrapper)}>
							<Button
								type="button"
								overrideClass={cx(modalStyles.btn, modalStyles.leftButton)}
								submitting={submitting}
								data-qa="SocialResetPasswordCancelBtn"
								onClick={onModalClose}
							>
								<CmsContentRenderer.Span
									contentKey={cms.pwCancel}
									fallbackValue="Cancel"
								/>
							</Button>
							<Button
								type="submit"
								overrideClass={cx(modalStyles.btn, modalStyles.rightButton)}
								data-qa="SocialResetPasswordSubmitBtn"
								submitting={submitting}
							>
								<CmsContentRenderer.Span
									contentKey={cms.pwSubmit}
									fallbackValue="Save new password"
								/>
							</Button>
						</div>
					</form>
				</Lifecycles>
			</Modal>
		)}</CmsContentList>);
};

SocialResetPassword.propTypes = {
	onSubmitDelink: PropTypes.func.isRequired,
};

export default SocialResetPassword;
