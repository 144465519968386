import React from 'react';
import PropTypes from 'prop-types';
import { values } from 'lodash';
import CmsContentList from 'components/data/CmsContentList.js';
import CmsContentRenderer from 'components/data/CmsContentRenderer.js';
import Banner, { TYPE_WARNING } from 'components/Banner.js';
import { getPathByRoute } from 'App.js';
import routeKeys from 'CustomerRouteKeys.js';

import Button, {
	Secondary,
} from 'components/Button.js';
import * as style from './RegisterForAccount.module.css';


const cms = {
	title: 'miscText["dashboard-banner-replace.title"]',
	description: 'miscText["dashboard-banner-replace.description"]',
	cta: 'miscText["dashboard-banner-replace.cta"]',
	link: 'miscText["dashboard-banner-replace.cta-url"]',
	btn: 'miscText["dashboard-banner-replace.button"]',
};

/**
 * This is the full width banner that appears above the Card Details on
 * Card Overview page after user has registered and tried to replace a Charlie Card as a Guest
 */

const ReplaceCard = ({ transit_account_id }) =>
	<CmsContentList list={values(cms)}>{({ cmsContent }) => (
		<Banner
			type={TYPE_WARNING}
			title={<CmsContentRenderer.Span contentKey={cms.title} fallbackValue="Replace Your Card" />}
			body={
				<CmsContentRenderer.P contentKey={cms.description} fallbackValue="As a registered user, you can now replace your card." />
			}
			link={
				<a href={cmsContent[ cms.link ]} className={style.registerButtonOverride}>
					<CmsContentRenderer.Span
						contentKey={cms.cta}
						fallbackValue="Learn more about card replacement"
					/>
				</a>
			}
			rightSide={
				<Button
					to={getPathByRoute(routeKeys.AccountCardBlockOrReplace, {
						transit_account_id,
						replacedTravelTokenId: transit_account_id,
					})}
					typeStyle={Secondary}
				>
					<CmsContentRenderer.Span contentKey={cms.btn} fallbackValue="Replace Your Card" />
				</Button>
			}
		/>
	)}</CmsContentList>;

ReplaceCard.propTypes = {
	transit_account_id: PropTypes.string.isRequired,
};

export default ReplaceCard;
